<template>
  <SelectCard
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ $t('support') }}
    </template>
    <SingleProduct
      v-for="type in Object.keys(productGroup)"
      :key="type"
      :status="productGroup[type].id === selectedProduct.model ? 'selected' : 'active'"
      @click.native="changeModel(productGroup[type].id)"
    >
      <template v-slot:image>
        <PictureSet
          v-if="productGroup[type]"
          :src="productGroup[type].image"
          :alt="productGroup[type].image"
          :title="productGroup[type].image"
          :size="imageSize"
        />
      </template>
      <template v-slot:label>
        <strong>{{ $t('types.' + type) }}</strong>
      </template>
    </SingleProduct>
  </SelectCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SelectCard from 'ui/src/components/SelectCard'
import SingleProduct from 'ui/src/components/SingleProduct'
import PictureSet from 'ui/src/components/PictureSet'

export default {
  name: 'SupportCardMenu',
  components: { SelectCard, SingleProduct, PictureSet },
  i18n: {
    messages: {
      'it-IT': {
        support: 'Appoggio',
        types: {
          floor: 'A terra',
          legs: 'Con gambe',
          wall: 'Sospesa'
        }
      },
      en: {
        support: 'Support',
        types: {
          floor: 'To the ground',
          legs: 'With legs',
          wall: 'Suspended'
        }
      },
      fr: {
        support: 'Support',
        types: {
          floor: 'Au sol',
          legs: 'Avec jambes',
          wall: 'Suspendu'
        }
      },
      es: {
        support: 'Apoyo',
        types: {
          floor: 'Al suelo',
          legs: 'Con piernas',
          wall: 'Suspendido'
        }
      }
    }
  },
  data () {
    return {
      imageSize: [
        {
          media: 1920,
          attr: {
            w: 720,
            h: 482,
            fit: 'fill'
          }
        },
        {
          media: 768,
          attr: {
            w: 600,
            h: 402,
            fit: 'fill'
          }
        },
        {
          media: 0,
          attr: {
            w: 380,
            h: 254,
            fit: 'fill'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ selectedProduct: 'selectedProduct', modelId: 'modelId', productGroup: 'productGroup', modelOptions: 'modelOptions' })
  },
  methods: {
    ...mapMutations({ setModelId: 'setModelId', setModelOptions: 'setModelOptions', setIsChangingGroup: 'setIsChangingGroup' }),
    changeModel (tesyId) {
      this.setIsChangingGroup(true)
      this.setModelId(tesyId)

      // const eventLabel = process.env.VUE_APP_ENVIRONMENT === 'dealers'
      //   ? `[LISTINO_DIGITALE] Change - ${this.modelId}`
      //   : `Change - ${this.modelId}`

      // // eslint-disable-next-line
      // const event_category = process.env.VUE_APP_ENVIRONMENT === 'dealers'
      //   ? 'ListinoDigitale'
      //   : 'Configuratore'

      // // eslint-disable-next-line
      // const event_label = process.env.VUE_APP_ENVIRONMENT === 'dealers'
      //   ? '[LISTINO_DIGITALE] model'
      //   : 'model'

      // this.$gtag.event(eventLabel, {
      //   event_category,
      //   event_label
      // })
    }
  }
}
</script>
