/* eslint-disable */

// import config from '@/components/Object3d/config/config.json'
import store from '@/store'
import sceneConfigs from './sceneConfigs.js'
// import Cookies from 'js-cookie'
// import merge from 'lodash.merge'

export default {
  async inserted (el, bind) {
    window.BABYLON.SceneLoader.ShowLoadingScreen = () => false
    await insert(el)(bind)

    // weird
    appViewer.addPointerDoubleTabObserver(async function (eventData) {

      const mesh = eventData.pickedMesh
      if (!(mesh.isVisible && mesh.isPickable)) return
      store.commit('menu/closeAllMenuCards')
      const _cat = appViewer.engine3d.getMeshAppData(mesh)
      const c = appViewer.getElementSelected().articoloGemo.getCategorie().find(c => c.c_categoria === _cat.categoriaGemo)
      if (c) {
        if (store.getters.pro) {
          const children = appViewer.getElementSelected().articoloGemo.articoliGemoChildMap
          const id = eventData.pickedMesh._parentNode._parentNode._appdata.idx
          if (children[id]) {
            let p = children[id]
            await appViewer.getElementSelected().articoloGemo.setArticoloGemoSelected(p)
            store.commit('setEditSingle', true)
          }
        }
        store.commit('menu/openMenuCard', ['customize', c.c_categoria])
      }      
    })
  },
  async update (el, bind) {
    if (Object.keys(bind.oldValue.options).length < 1) return
    if (JSON.stringify(bind.value) === JSON.stringify(bind.oldValue)) return
    if (!appViewer.getElementSelected()) return
    if (bind.value.id !== bind.oldValue.id) {
      store.commit('setLoader', true)
      // console.log('update', bind)
      const resetOptions = Object.assign(bind, { value: { id: bind.value.id, options: {} } }, {})
      insert(el)(resetOptions)
      if(!appViewer.getElementSelected()) return
      const event = new window.CustomEvent('change:id', { detail: appViewer.getElementSelected().c_articolo })
      el.dispatchEvent(event)
    }
    await dressObject(bind.value)
  }
}

const appViewer = new window.ENGINE3D.AppViewer3d()

const insert = (el) => async (bind) => {
  const appViewer = await initObject(el)(bind.value)

  const dressResult = await dressObject(bind.value)

  const mobileMediaQuery = typeof window !== 'undefined' ? window.matchMedia('(max-width: 768px)') : { matches: false }
  const orientationMediaQuery = typeof window !== 'undefined' ? window.matchMedia('(orientation:portrait)') : { matches: false }

  if (mobileMediaQuery.matches && orientationMediaQuery.matches) {
    sceneConfigs.sceneConfigsMobile(appViewer, bind.value.id)
  } else {
    sceneConfigs.sceneConfigs(appViewer, bind.value.id)
  }


  if (!dressResult) {
    // console.info('error dressing')
    const event = new window.CustomEvent('error', { detail: () => appViewer })
    el.dispatchEvent(event)
    return
  }

  if (!Object.keys(bind.value.options).length) {
    const options = appViewer.getElementSelected().articoloGemo.getCategorie()
      .filter((cat) => cat.Valori.length > 1)
      .reduce((obj, item) => Object.assign(obj, { [item.c_categoria]: item.c_valore_scelto }), {})

    // console.info('got options from tesy', options)
    // store.commit('setModelOptions', options)
    setModelOptions(options)
    store.commit('setLoader', false)
    const event = new window.CustomEvent('ready', { detail: () => appViewer })
    el.dispatchEvent(event)
    return
  }

  store.commit('setLoader', false)
  appViewer.getElementSelected().model3d.focus()
  // we are ready
  const event = new window.CustomEvent('ready', { detail: () => appViewer })
  el.dispatchEvent(event)
  // await appViewer.ambient3d.toggleLightsWithAnimation()
}

const initObject = (el) => async ({ id, options, locale }) => {
  // return new Promise(async (resolve, reject) => {
  // })
  if (!appViewer.viewer3d) {
    // console.info('Initializing viewer…')
    await appViewer.loadConfig({
      configPath: 'https://lagoconfigurator.servicetesy.com/public/configs/prod/'
      // configPath: 'https://configuratore.lago.it/public/configs/prod/'
    })
    // appViewer.initViewer(el, {
    //   modelSelectControl: false
    // })
  }

  // const loader = new DefaultLoader()

  await appViewer.init(el)
  // appViewer.engine3d.engine.loadingScreen = loader

  try {
    // console.log('sf_access_token A:', store.getters.userDetails.sf_access_token)

    await appViewer.connectWithSSOSalesForce(store.getters.userDetails.sf_access_token)
  } catch (error) {
    // console.log('Errore login TESI (1):', error)
  }
  appViewer.deactivateMainInteractors()

  await appViewer.setLanguage(locale === 'it-IT' ? 'it' : locale)
  window.ENGINE3D.Ambient3d.buildViewerAmbient(appViewer)

  let lightRight = appViewer.engine3d.scene.getLightByName("lightRight")
  let lightFront = appViewer.engine3d.scene.getLightByName("lightFront")
  lightFront.intensity = 2;
  lightRight.direction = new BABYLON.Vector3(-1, -1, 0.5);
  lightRight.intensity = 3;

  try { 
    // console.log('sf_access_token B:', store.getters.userDetails.sf_access_token)
    await appViewer.connectWithSSOSalesForce(store.getters.userDetails.sf_access_token)
    const variantiScelte = Object.keys(options).length
      ? Object.keys(options)
        .map((key) => ({
          c_categoria: key,
          c_valore: options[key]
        }))
      : {}
    await addArticolo(appViewer, {
      c_articolo: id,
      variantiScelte
    })
  } catch (error) {
    // console.log('Errore login TESI (2)', error)
    const variantiScelte = Object.keys(options).length
      ? Object.keys(options)
        .map((key) => ({
          c_categoria: key,
          c_valore: options[key]
        }))
      : {}
    await addArticolo(appViewer, {
      c_articolo: id,
      variantiScelte
    })
    appViewer.engine3d.updateShadows()
  }

  return appViewer
}

const addArticolo = async (appViewer, params) => {
  // appViewer.removeArticoli()
  await appViewer.removeElements()
  // const art = params.c_articolo.toUpperCase().startsWith('WEB') ? params.c_articolo.toUpperCase() : 'WEB_' + params.c_articolo.toUpperCase()
  await appViewer.insertElementGest({
    c_articolo: params.c_articolo.toUpperCase() || '*',
    c_famiglia: params.c_famiglia || '*',
    c_modello: params.c_modello || '*',
    position: [0, 0, 0],
    removePrevious: true,
    focus: true,
    select: true
  })
// appViewer.addArticolo(params, (e, articoloGemo) => {
//   if (e) { reject(e) }
//   resolve()
// })
}

const dressObject = async ({ options }) => {
  const object3d = appViewer.getElementSelected()
  // const object3d = appViewer.getArticoloSelected()
  // const currentValues = object3d.articoloGemo.getValoriSceltiVariante()
  const currentValues = object3d.articoloGemo.variantiScelte
  const newValues = compare(currentValues, options)
  if (Object.keys(newValues).length < 1) {
    // console.info('No new values, stop dressing.')
    return true
  }
  // console.info('found', newValues)
  // // loader
  if (!store.getters.loader) store.commit('setLoader', true)

  // let cookie = Cookies.get('changing_group')
  // cookie = cookie ? JSON.parse(cookie) : false
  // cookie = merge(cookie, newValues)
  // if (cookie) {
  //     let entries = {}
  //     let stored_vals = Cookies.get('model_options')
  //     stored_vals = stored_vals ? JSON.parse(stored_vals) : false
  //     if (stored_vals) {
  //       entries = merge(stored_vals, newValues)
  //     } else {
  //       entries = merge(currentValues, newValues)
  //     }
  //     for (const [key, value] of Object.entries(entries)) {
  //       promises.push(appViewer.configureElementVariant(object3d, key, [value]))
  //     }
  //   // Cookies.remove('changing_group')
  //   // Cookies.remove('model_options')
  // } else {
  // }
  if (Object.keys(newValues).length > 1) {
    let changes = []
    for (const [key, value] of Object.entries(newValues)) {
      changes.push({
        id_variant: key,
        id_value: value
      })
    }
    await appViewer.configureElementVariants(object3d, changes)
    // await appViewer.getElementSelected().articoloGemo.vesti()
    // appViewer.getElementSelected().articoloGemo.setVariantiScelte(changes)
    // await appViewer.configureElementVariant(object3d, changes[0].c_categoria, [changes[0].c_valore])
  } else {
    const promises = []
    for (const [key, value] of Object.entries(newValues)) {
      promises.push(appViewer.configureElementVariant(object3d, key, [value]))
    }
    await Promise.all(promises)
  }


  
  // if (store.state.isChangingGroup) {
  // } else {
  // }


  // const dressing = Object
  //   .entries(newValues)
  //   .filter(([key, value]) => !!value)
  // console.log(dressing)
  // //   .map(([key, value]) => {
  // //     return dressFunction(object3d, key, [value])
  // //   })
  // //   .reduce(async (total, dressFunction) => {
  // //     const syncTotal = await total
  // //     console.info('Dressing…')
  // //     const result = await dressFunction().catch((e) => {
  // //       console.error(e)
  // //     })
  // //     console.info('Dressed')
  // //     return syncTotal && result
  // //   }, true)
  // // const result = await dressing
  setModelOptions()
  appViewer.engine3d.updateShadows()
  store.commit('setLoader', false)
  store.commit('dress')
  return true
}

const compare = (values, options) => {
  let currentValues = values.map((value) => ({
    [value.c_categoria]: value.c_valore
  }))
  currentValues = Object.assign({}, ...currentValues)

  return Object.keys(options)
    .reduce((diff, key) => {
      if (currentValues[key] === options[key]) return diff
      return {
        ...diff,
        [key]: options[key]
      }
    }, {})
}

appViewer.onModelGeometryUpdateObservable.add(element => {
  var model3d = element.model3d;
  if (!model3d) { return; }
  var geometryMeshes = model3d.getGeometryMeshes();
  if (!geometryMeshes.length) { return; }


  if (!appViewer.topViewModeEnabled && appViewer.elements.length <= 1) {
      appViewer.updateCameraView(model3d);
  }
  
  model3d.show()
  appViewer.engine3d.updateShadows()
})


const setModelOptions = () => {
  const articleOptions = appViewer.getElementSelected().articoloGemo.getCategorie()
    .filter((cat) => cat.Valori.length > 1)
    .reduce((obj, item) => Object.assign(obj, { [item.c_categoria]: item.c_valore_scelto }), {})
  
  // let children = appViewer.getElementSelected().articoloGemo.articoliGemoChildMap
  // children = Object.values(children).filter(_ => _.elemento.fg_vestizione_modificabile === 1)
  // console.log(children.map(_ => _.elemento))
  store.commit('setModelOptions', articleOptions)
}
// const DefaultLoader = () => {}
// DefaultLoader.prototype.displayLoadingUI = () => {
//   return false
// }
// DefaultLoader.prototype.hideLoadingUI = () => {
//   return false
// }
