<template>
  <Feedback
    ref="feedback"
    @toggled="(state) => $emit('toggled', state)"
    @submit="comment => $emit('submit', comment)"
    @positive="positive"
    @negative="negative"
  >
    <template v-slot:title>
      {{ $t('title') }}
    </template>
    <template v-slot:title-negative>
      {{ $t('titleNegative') }}
    </template>
    <template v-slot:title-final>
      {{ $t('titleFinal') }}
    </template>
    <template v-slot:description>
      {{ $t('description') }}
    </template>
    <template v-slot:description-final>
      {{ $t('descriptionFinal') }}
    </template>
    <template v-slot:description-negative>
      {{ $t('descriptionNegative') }}
    </template>
    <template v-slot:error>
      {{ $t('error') }}
    </template>
    <template v-slot:button>
      {{ $t('button') }}
    </template>
  </Feedback>
</template>

<script>
import { mapGetters } from 'vuex'
import Feedback from 'ui/src/components/Feedback'

/*
  Feedback Value > $refs.feedback.feedbackValue = 'positive' | 'negative'
  Textarea v-model > $refs.feedback.comment
  Submit form > $refs.feedback.submit()
*/

export default {
  components: {
    Feedback
  },
  i18n: {
    messages: {
      'it-IT': {
        title: 'Grazie',
        titleNegative: 'Grazie per il tuo feedback',
        titleFinal: 'Grazie per il tuo feedback',
        description: 'La tua opinione per noi è importante',
        descriptionFinal: 'Vieni a trovarci nei punti vendita LAGO e rimani aggiornato su tutte le novità seguendo i nostri canali social',
        descriptionNegative: 'Per favore, descrivici i problemi che hai incontrato',
        error: 'È necessario compilare il form per proseguire',
        button: 'Invia'
      },
      en: {
        title: 'Thank you',
        titleNegative: 'Thank you for your feedback',
        titleFinal: 'Thank you for your feedback',
        description: 'Your opinion is important for us',
        descriptionFinal: 'Visit the LAGO shop and stay updated on all the news by following our social channels.',
        descriptionNegative: 'Please, describe us the problems you found',
        error: 'You must fill in the form to continue',
        button: 'Send'
      },
      fr: {
        title: 'Merci',
        titleNegative: 'Merci pour votre suggestion',
        titleFinal: 'Merci pour votre suggestion',
        description: 'Votre opinion est important pour nous ',
        descriptionFinal: 'Venez nous rendre visite dans les magasins LAGO et suivez nos canaux sociaux pour voir toutes les nouveautés',
        descriptionNegative: 'S’il vous plaît, décrivez-nous les problèmes que vous avez rencontrés',
        error: 'Vous devez remplir le formulaire pour continuer',
        button: 'Envoie'
      },
      es: {
        title: 'Gracias',
        titleNegative: 'Gracias por su comentario',
        titleFinal: 'Gracias por su comentario',
        description: 'Tu opinión es importante para nosotros',
        descriptionFinal: 'Ven a visitarnos en los puntos de venta LAGO y mantente informado de todas las novedades siguiendo nuestros canales sociales',
        descriptionNegative: 'Por favor, describe los problemas que encontraste',
        error: 'Debe rellenar el formulario para continuar',
        button: 'Envía'
      }
    }
  },
  computed: {
    ...mapGetters({
      modelId: 'modelId',
      userDetails: 'userDetails'
    })
  },
  mounted () {
    this.open()
  },
  methods: {
    toggle () {
      this.$refs.feedback.toggle()
    },
    open () {
      this.$refs.feedback.open()
    },
    positive () {
      this.$gtag.event('Configuratore [like]', {
        event_category: process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    },
    negative () {
      this.$gtag.event('Configuratore [Dislike]', {
        event_category: process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    }
  }
}
</script>
