export default {
  namespaced: true,
  state: {
    menuCardsOpen: [],
    activeCards: {},
    defaultActiveCategory: null
  },
  getters: {
    menuCardsOpen: state => state.menuCardsOpen,
    activeCards: state => state.activeCards,
    defaultActiveCategory: state => state.defaultActiveCategory,
    isMenuCardOpen: state => card => {
      if (Array.isArray(card)) {
        return card.every((c) => state.menuCardsOpen.includes(c))
      }
      return state.menuCardsOpen.includes(card)
    }
  },
  mutations: {
    openMenuCard (state, payload) {
      const card = payload.card || payload
      if (payload.close) {
        state.menuCardsOpen = []
      }

      if (Array.isArray(card)) {
        card
          .filter((card) => !state.menuCardsOpen.includes(card))
          .map((c) => state.menuCardsOpen.push(c))
        return
      }
      if (state.menuCardsOpen.includes(card)) return
      state.menuCardsOpen.push(card)
    },
    closeMenuCard (state, card) {
      const cards = Array.isArray(card) ? card : [card]
      const indexes = cards.map(card => state.menuCardsOpen.indexOf(card))
      console.log(state.menuCardsOpen, indexes, card)
      if (indexes < 0) return
      const lowerIndex = Math.min(...indexes)
      console.log(lowerIndex)
      state.menuCardsOpen = state.menuCardsOpen.filter((_, i) => i < lowerIndex)
    },
    closeAllMenuCards (state) {
      state.menuCardsOpen = []
    },
    setActiveCards (state, active) {
      state.activeCards = { ...state.activeCards, ...active }
    },
    resetActiveCards (state) {
      state.activeCards = {}
    },
    setDefaultActiveCategory (state, category) {
      state.defaultActiveCategory = category
    }
  }
}
