import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    props: route => ({ locale: route.query.locale, model: route.query.model, options: route.query.options || {} })
  }
]

const router = new VueRouter({
  routes,
  parseQuery (q) {
    return require('qs').parse(q)
  },
  stringifyQuery (q) {
    const r = require('qs').stringify(q)
    return r ? '?' + r : ''
  }
})

export default router
