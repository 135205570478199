<script>
import { mapGetters } from 'vuex'
// import { tesyLocaleMap } from './locales'

export default {
  computed: {
    ...mapGetters({
      appViewerReady: 'appViewerReady',
      appViewerGetter: 'appViewerGetter'
    }),
    currentLocale: {
      get () {
        return this.$i18n.locale
      }
    }
  },
  watch: {
    appViewerReady: {
      handler (value) {
        // if (!value) return
        // this.appViewerGetter().api.setLanguage(tesyLocaleMap[this.currentLocale], this.updateArticle)
      },
      immediate: true
    }
  },
  methods: {
    updateArticle () {
      this.appViewerGetter().getElementSelected().vesti({ fg_calcola_prezzo: 1 }, () => this.$emit('change', this.currentLocale))
    }
  },
  render: () => ''
}
</script>
