<script>

import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({ modelOptions: 'modelOptions', modelId: 'modelId', isChangingAll: 'isChangingAll', appViewerReady: 'appViewerReady', appViewerGetter: 'appViewerGetter', userDetails: 'userDetails' })
  },
  data () {
    return {
      checkSessionInterval: null,
      counter: 0
    }
  },
  watch: {
    modelOptions: {
      handler (value, oldValue) {
        if (Object.keys(oldValue).length === 0 || JSON.stringify(value) === JSON.stringify(oldValue)) return
        parent.postMessage({ type: 'update', id: this.modelId, options: this.modelOptions }, '*')
      }
    },
    modelId: {
      handler (value, oldValue) {
        if (!value || value === oldValue) return
        parent.postMessage({ type: 'update', id: this.modelId, options: this.modelOptions }, '*')
      }
    },
    '$route.fullPath': {
      handler () {
        // Check if configurator is load inside an iframe
        if (window.self !== window.top) return
        this.setUrl(window.location.href)
      },
      immediate: true
    },
    appViewerReady: {
      handler () {
        console.log('APPVIEWRREADY')
        if (this.userDetails) this.checkSession(true)
        if (this.appViewerReady && this.tryToOpenOrderForm) {
          console.log('show form attempt')
          this.setShowCheckoutForm(true)
          this.getScreenshot()
        }

        this.$gtag.set('user_properties', {
          user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
        })
        // this.$gtag.customMap({ user_logged: this.userDetails !== null })
        // this.$gtag.config({
        //   id: 'G-MSEGZPEMHR',
        //   params: {
        //     send_page_view: false,
        //     'custom_map': { user_logged: this.userDetails !== null }
        //   }
        // })
      }
    }
  },
  mounted () {
    window.addEventListener('message', (e) => {
      if (e.data && e.data.type === 'url') {
        this.setUrl(e.data.url)
        // console.log('receive url message')
      }
      if (e.data && e.data.type === 'userInfo') {
        this.setUserDetails(e.data.data)
        // QUI WATCHER
        // Ogni due minuti controlliamo se la sessione è attiva, altrimenti slogghiamo
        if (!this.checkSessionInterval) this.checkSessionInterval = setInterval(this.checkSession, 600000)
      }
      /*  if (e.data && e.data.type === 'logOut') {
        this.logOut()
      } */
      if (e.data && e.data.type === 'showBuyForm') {
        this.tryToOpenOrderForm = true
        /* this.setShowCheckoutForm(true)
        this.getScreenshot() */
      }
      if (!e.data.options) return
      if (Object.keys(e.data.options).length < 1 || JSON.stringify(e.data.options) === JSON.stringify(this.modelOptions)) return
      // console.info('Receiving message…')
      this.setModelOptions(e.data.options)
    })
  },
  methods: {
    checkSession (errorMessage = false) {
      if (!this.appViewerGetter) return
      if (this.appViewerReady && !this.appViewerGetter().session.logged) {
        console.log('Session is not active anymore, logging out')
        this.appViewerGetter().logout()
        this.logOut()
        parent.postMessage({ type: 'logoutFromConfigurator', id: 'logout request', options: { } }, '*')
        clearInterval(this.checkSessionInterval)
        if (errorMessage) {
          parent.postMessage({ type: 'accountError', id: 'account error', options: { } }, '*')
        }
      }
    },
    ...mapMutations({ setModelOptions: 'setModelOptions', readyToCreateObject: 'readyToCreateObject', setUrl: 'setUrl', setUserDetails: 'setUserDetails', logOut: 'logOut', setShowCheckoutForm: 'setShowCheckoutForm' }),
    ...mapActions({ getScreenshot: 'getScreenshot' })
  },
  render: () => ''
}
</script>
