<template>
  <SelectCard
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ $t('variant') }}
    </template>
    <template v-slot:description>
      <div>
        {{ $t('intro') }}
      </div>
    </template>

    <div
      v-for="(dim,i) in dimensions"
      :key="dim.h"
    >
      <strong
        class="hTitle"
        :class="{ active: show === i }"
        @click="selectGroup(i)"
        v-show="show == null || show == i"
      >
        Altezza {{ dim.h }}cm
      </strong>
      <transition
        name="fade"
      >
        <div
          v-if="show == i"
          class="hGroup"
        >
          <strong class="widths">
            {{ $t('widths') }}
          </strong>
          <div class="box_wrapper">
            <div
              v-for="w in dim.widths"
              :key="w.w"
              class="wBox"
              @click="changeModel(w.model)"
            >
              {{ w.w }}cm
            </div>
          </div>
        </div>
      </transition>
    </div>
  </SelectCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SelectCard from 'ui/src/components/SelectCard'
// import PictureSet from 'ui/src/components/PictureSet'

export default {
  name: 'VariantsCardMenu',
  components: { SelectCard },
  i18n: {
    messages: {
      'it-IT': {
        variant: 'Altezze',
        intro: 'Seleziona l\'altezza per vedere le larghezze disponibili',
        widths: 'Larghezze disponibili'
      },
      en: {
        variant: 'Heights',
        intro: 'Select the height to see the available widths',
        widths: 'Available widths'
      },
      fr: {
        variant: 'Hauteur',
        intro: 'Sélectionnez la hauteur pour voir les largeurs disponibles',
        widths: 'Largeurs disponibles'
      },
      es: {
        variant: 'Alturas',
        intro: 'Seleccione la altura para ver las anchuras disponibles',
        widths: 'Anchuras disponibles'
      }
    }
  },
  props: {
    dimensions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      show: null,
      imageSize: [
        {
          media: 1920,
          attr: {
            w: 720,
            h: 482,
            fit: 'fill'
          }
        },
        {
          media: 768,
          attr: {
            w: 600,
            h: 402,
            fit: 'fill'
          }
        },
        {
          media: 0,
          attr: {
            w: 380,
            h: 254,
            fit: 'fill'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ modelId: 'modelId', productGroup: 'productGroup', modelOptions: 'modelOptions', selectedProduct: 'selectedProduct' })
  },
  methods: {
    ...mapMutations({ setModelId: 'setModelId', setModelOptions: 'setModelOptions' }),
    changeModel (tesyId) {
      this.setModelId(tesyId)
    },
    selectGroup (i) {
      if (this.show === i) {
        this.show = null
      } else {
        this.show = i
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .wBox {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    padding: 15px;
    border: 1px solid black;
  }
  .hGroup {
    text-align: center;
  }
  .widths {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .hTitle {
    cursor: pointer;
    margin: 10px 0;
    padding: 15px;
    display: block;
    font-size: 20px;
    border: 1px solid #D2D2D2;
    &:hover {
      border: 1px solid #202020;
    }
    &.active {
      border: 1px solid #202020;
    }
  }

  .box_wrapper {
    margin-top: 20px;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    @media all and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .show {
    display: block;
  }
</style>
