import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const hash = window.location.hash
const query = hash.replace('#/', '')
const urlParams = new URLSearchParams(query)
const locale = urlParams.get('locale') || 'it-IT'

export const i18n = new VueI18n({
  locale,
  fallbackLocale: ['en']
})
