<template>
  <Switcher
    v-if="option.values"
    :selected="selected"
    @toggle="toggle"
  />
</template>

<script>
import Switcher from 'ui/src/components/CustomizationsCard/Types/Switcher'

import changeState from '../mixins/changeState'

export default {
  name: 'SwitcherCardMenu',
  components: { Switcher },
  mixins: [changeState],
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  computed: {
    selected: {
      get () {
        const selected = this.option.values
          .find((val) => val.c_valore === this.modelOptions[this.option.id])
        if (!selected) return false
        return selected.c_valore === 'SI'
      }
    }
  },
  methods: {
    toggle () {
      this.changeState(this.option.id, this.option.values.find((v) => v.c_valore !== this.option.selected).c_valore)
    }
  }
}
</script>
