<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      dressCount: 'dressCount',
      appViewerReady: 'appViewerReady',
      appViewerGetter: 'appViewerGetter',
      modelOptions: 'modelOptions'
    })
  },
  watch: {
    dressCount: {
      handler () {
        // if (!this.appViewerReady) return

        // const emptyCategoriesCodes = this.appViewerGetter()
        //   .getElementSelected()
        //   .categorie
        //   .filter((category) => category.Valori.length <= 0)
        //   .map(c => c.c_categoria)

        // const toRemove = Object
        //   .keys(this.modelOptions)
        //   .filter((option) => emptyCategoriesCodes.includes(option))

        // const cleanedOptions = Object
        //   .entries(this.modelOptions)
        //   .map(([key, value]) => {
        //     if (toRemove.includes(key)) return {}
        //     return { [key]: value }
        //   })
        //   .reduce((acc, option) => Object.assign({}, acc, option), {})

        // const currentCategories = Object.keys(this.modelOptions)
        // const cleanedCategories = Object.keys(cleanedOptions)
        // if (currentCategories.some(option => !cleanedCategories.includes(option))) {
        //   this.setModelOptions(cleanedOptions)
        // }
      }
    }
  },
  methods: {
    ...mapMutations({
      setModelOptions: 'setModelOptions'
    })
  },
  render: () => ''
}
</script>
