<template>
  <div id="app" :class="test">
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    test () {
      return process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore'
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Lago Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

body {
  margin: 0;
}

* {
  font-variant-ligatures: none;
}
</style>
