<template>
  <Object3dContainer
    v-if="modelId && modelOptions"
    :key="modelId"
    v-object3d="{ id: modelId, options: Object.assign({}, modelOptions, $route.query.options), locale: $i18n.locale }"
    :translate="menuCardsOpen.length > 0"
    @ready.native="handleAppViewerReady"
    @error.native="appViewerError"
    @change:id.native="idChanged"
    @doubleclick="doubleClick"
  >
    <template
      v-if="name"
      v-slot:name
    >
      {{ name }}
    </template>
    <template
      v-if="size"
      v-slot:size
    >
      {{ size }}
    </template>
    <template
      v-if="showPrice && price && Number(price) > 0"
      v-slot:mprice
    >
      Prezzo consigliato al pubblico: <br>
      <strong>{{ getCurrency }} {{ priceIncrease ? Math.round(price + price*priceIncrease) : Math.round(price) }}</strong>
    </template>
    <template
      v-if="showPrice && price && Number(price) > 0"
      v-slot:price
    >
      <strong>{{ getCurrency }} {{ priceIncrease ? Math.round(price + price*priceIncrease) : Math.round(price) }}</strong> <br>
      <span>
        {{ $t('priceAlert') }}
      </span>
    </template>
  </Object3dContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import object3d from './object3d'
import Object3dContainer from 'ui/src/components/Object3dContainer'

export default {
  name: 'Object3d',
  components: {
    Object3dContainer
  },
  directives: {
    object3d
  },
  i18n: {
    messages: {
      it: {
        priceLabel: 'Prezzo consigliato al pubblico',
        priceAlert: 'Il prezzo si riferisce al listino Lago corrente ufficiale, trasporto e montaggio esclusi. La rappresentazione grafica dei prodotti è realizzata al solo scopo di illustrare il prodotto ed è puramente indicativa.'
      },
      en: {
        priceLabel: 'Prezzo consigliato al pubblico',
        priceAlert: 'The price refers to the official Lago current price list, excluding transport and assembly. The graphic representation of the products is made for the sole purpose of illustrating the product and is purely indicative.'
      },
      es: {
        priceLabel: 'Prezzo consigliato al pubblico',
        priceAlert: 'El precio se refiere a la lista de precios oficial Lago actual, excluyendo transporte y montaje. La representación gráfica de los productos se realiza con la única finalidad de ilustrar el producto y tiene un carácter puramente indicativo.'
      },
      fr: {
        priceLabel: 'Prezzo consigliato al pubblico',
        priceAlert: 'Le prix se réfère au tarif officiel du Lago en vigueur, hors transport et montage. La représentation graphique des produits est faite dans le seul but d\'illustrer le produit et est purement indicative.'
      }
    }
  },
  filters: {
    formattedPrice (p, locale) {
      const toCurrency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR'
      })
      return toCurrency.format(p)
    }
  },
  data: () => ({
    name: '',
    size: null,
    price: null
  }),
  computed: {
    ...mapGetters({
      modelId: 'modelId',
      modelOptions: 'modelOptions',
      appViewerGetter: 'appViewerGetter',
      articleId: 'articleId',
      isReady: 'appViewerReady',
      userDetails: 'userDetails'
    }),
    ...mapGetters('menu', { menuCardsOpen: 'menuCardsOpen' }),
    showPrice: {
      get () {
        return this.userDetails && this.userDetails.token && this.userDetails.role !== 'Architect'
      }
    },
    getCurrency () {
      if (this.userDetails && this.userDetails.currency) {
        const mapping = {
          Euro: '€',
          Dollaro: '$',
          Pound: '£'
        }
        return mapping[this.userDetails.currency]
      } else {
        return '€'
      }
    },
    priceIncrease () {
      if (this.userDetails && this.userDetails.price_increase) {
        return Number(this.userDetails.price_increase) / 100
      } else {
        return null
      }
    }
  },
  watch: {
    articleId: {
      handler () {
        if (!this.appViewerGetter) return
        this.name = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
        this.getSize()
        // this.price = this.appViewerGetter().getElementSelected().articoloGemo.elemento.PrezzoListino.n_prezzo_listino
        this.price = this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
      }
    },
    modelOptions: {
      handler () {
        if (!this.appViewerGetter) return
        // this.price = this.appViewerGetter().getElementSelected().articoloGemo.elemento.PrezzoListino.n_prezzo_listino
        this.price = this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
        this.getSize()

        /*
          if ((!this.appViewerGetter || typeof this.appViewerGetter !== 'function')) return '* * *'
          if (!this.appViewerGetter().session.logged) return '* * *'
          return this.appViewerGetter().getElementSelected().getInfo().prices.increasedPrice
          */
      }
    }
    // modelOptions () {
    //   if (!this.isReady) return
    //   this.getSize()
    // }
  },
  mounted () {
    this.$root.$on('updatePrice', () => {
      this.price = this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
    })
  },
  methods: {
    ...mapMutations({ setAppViewerGetter: 'setAppViewerGetter', setAppViewerReady: 'setAppViewerReady' }),
    handleAppViewerReady (e) {
      this.setAppViewerGetter(e.detail)
      this.setAppViewerReady(true)
      this.name = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
      this.getSize()
      // this.price = this.appViewerGetter().getElementSelected().articoloGemo.elemento.PrezzoListino.n_prezzo_listino
      console.log('PRICESSSS:', this.userDetails)
      if (this.userDetails?.role && this.userDetails.role.toLowerCase() === 'architect') {
        this.price = this.appViewerGetter().getElementSelected().getInfo().prices?.increasedPriceTotal || this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
      } else {
        this.price = this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
      }
    },
    appViewerError (e) {
      console.info('Error dressing', e)
    },
    idChanged (e) {
      this.setArticleId = e.detail
    },
    getSize () {
      const datiArticolo = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo
      if (!datiArticolo) return ''
      const size = {
        [datiArticolo.c_sigla_dim_1?.charAt(0) || 'L']: (datiArticolo.n_dim_1?.toLocaleString('it-IT') || '') + datiArticolo.c_um_dimensioni.toLowerCase(),
        [datiArticolo.c_sigla_dim_2?.charAt(0) || 'P']: (datiArticolo.n_dim_2?.toLocaleString('it-IT') || '') + datiArticolo.c_um_dimensioni.toLowerCase(),
        [datiArticolo.c_sigla_dim_3?.charAt(0) || 'A']: (datiArticolo.n_dim_3?.toLocaleString('it-IT') || '') + datiArticolo.c_um_dimensioni.toLowerCase()
      }
      console.log(datiArticolo)
      this.size = Object
        .keys(size)
        .map((s) => `${s} ${size[s]}`)
        .join(' – ')
    },
    doubleClick (e) {
      // console.log(e)
    }
  }
}
</script>
