<template>
  <SelectCard
    v-if="siblings"
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ $t('size') }}
    </template>
    <Select
      v-for="model in siblings"
      :key="model.id"
      :status="model.id === selectedProduct.model ? 'selected' : 'active'"
      @click.native="changeModel(model.tesyId)"
    >
      <span
        v-for="size in Object.keys(model.label)"
        :key="size"
      >
        <strong>{{ size }}:</strong> {{ model.label[size] }}
      </span>
    </Select>
  </SelectCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SelectCard from 'ui/src/components/SelectCard'
import Select from 'ui/src/components/SelectCard/Select'

export default {
  name: 'SiblingsCardMenu',
  components: { SelectCard, Select },
  i18n: {
    messages: {
      'it-IT': {
        size: 'Dimensioni'
      },
      en: {
        size: 'Size'
      },
      fr: {
        size: 'Dimensions'
      },
      es: {
        size: 'Dimensiones'
      }
    }
  },
  props: {
    siblings: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({ selectedProduct: 'selectedProduct', modelId: 'modelId', userDetails: 'userDetails' })
  },
  methods: {
    ...mapMutations({ setModelId: 'setModelId' }),
    changeModel (tesyId) {
      this.setModelId(tesyId)

      this.$gtag.event('change_model', {
        event_category: process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    }
  }
}
</script>
