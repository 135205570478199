<template>
  <div>
    <ProductsCard
      v-images-loaded="loaded"
      :overlap="overlap"
      @close="handleClose"
    >
      <template v-slot:title>
        {{ $t('toolbar.category') }}
      </template>

      <template
        v-if="entries"
        v-show="!loading"
      >
        <SingleProduct
          v-for="entry in entries"
          :key="entry.fields.tesyId"
          :status="categoryStatus[entry.sys.id]"
          @click.native="openSubcategory(entry)"
        >
          <template v-slot:image>
            <PictureSet
              v-if="entry.fields.cover"
              :src="entry.fields.cover.fields.file.url"
              :alt="entry.fields.cover.fields.file.url"
              :title="entry.fields.cover.fields.file.url"
              :size="imageSize"
            />
          </template>
          <template v-slot:label>
            {{ entry.fields.name }}
          </template>
        </SingleProduct>
      </template>
      <SkeletonTransition>
        <Skeleton v-if="loading" />
      </SkeletonTransition>
    </ProductsCard>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import imagesLoaded from 'vue-images-loaded'

import ProductsCard from 'ui/src/components/ProductsCard'
import PictureSet from 'ui/src/components/PictureSet'
import SingleProduct from 'ui/src/components/SingleProduct'
import SkeletonTransition from 'ui/src/components/Transitions/Skeleton'
import Skeleton from '../Skeleton'

export default {
  components: { ProductsCard, SingleProduct, PictureSet, Skeleton, SkeletonTransition },
  directives: {
    imagesLoaded
  },
  props: {
    overlap: {
      type: Boolean,
      default: () => false
    },
    currentCategory: {
      type: String,
      default: () => ''
    }
  },
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          category: 'Tipologia'
        }
      },
      en: {
        toolbar: {
          category: 'Type'
        }
      },
      fr: {
        toolbar: {
          category: 'Typologie'
        }
      },
      es: {
        toolbar: {
          category: 'Tipo'
        }
      }
    }
  },
  data: () => ({
    entries: [],
    selectedCategory: undefined,
    loading: true,
    imageSize: [
      {
        media: 1920,
        attr: {
          w: 720,
          h: 482,
          fit: 'fill'
        }
      },
      {
        media: 768,
        attr: {
          w: 600,
          h: 402,
          fit: 'fill'
        }
      },
      {
        media: 0,
        attr: {
          w: 380,
          h: 254,
          fit: 'fill'
        }
      }
    ]
  }),
  computed: {
    ...mapGetters({ modelId: 'modelId', selectedProduct: 'selectedProduct' }),
    categoryStatus: {
      get () {
        return this.entries.map((e) => {
          return {
            key: e.sys.id,
            value: e.sys.id === this.selectedCategory
              ? 'selected'
              : e.fields.tesyId === this.currentCategory
                ? 'current  '
                : 'active'
          }
        })
          .reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
      }
    }
  },
  watch: {
    modelId: {
      async handler () {
        const envs = await this.$contentful.getEntry('1dai2ZvAT8r2zVRAqgqv1B', {
          include: 10,
          locale: this.$i18n.locale
        })

        this.entries = envs.fields.environments
          .reduce((entries, env) => {
            return entries.concat(env.fields.categories)
          }, [])
          .filter((cat) => cat.fields.subcategories)

        this.selectedCategory = this.selectedProduct.category
      },
      immediate: true
    }
  },
  methods: {
    openSubcategory (entry) {
      this.closeMenuCard('product')
      this.setActiveCards({ category: entry.fields.tesyId })
      this.openMenuCard('subcategory')
      if (entry.fields.subcategories.length === 1) {
        this.openMenuCard('product')
        this.setActiveCards({ subcategory: entry.fields.subcategories[0].fields.slug })
      }
    },
    loaded () {
      this.loading = false
    },
    ...mapMutations('menu', { openMenuCard: 'openMenuCard', closeMenuCard: 'closeMenuCard', setActiveCards: 'setActiveCards', resetActiveCards: 'resetActiveCards' }),
    handleClose () {
      this.resetActiveCards()
      this.closeMenuCard('category')
    }
  }
}
</script>
