<template>
  <div>
    <Utility
      v-if="isTidioActive"
      icon="Support"
      :notification="notification"
      @click.native="toggleChat"
    >
      {{ !isOpen ? $t('support') : $t('close') }}
    </Utility>
  </div>
</template>

<script>
import Utility from 'ui/src/components/Utilities/Utility'
import { mapGetters } from 'vuex'

export default {
  components: {
    Utility
  },
  data: () => ({
    isOpen: false,
    isTidioActive: false,
    notification: false
  }),
  mounted () {
    const tidioScript = document.createElement('script')
    tidioScript.setAttribute('async', true)
    tidioScript.setAttribute('type', 'text/javascript')
    tidioScript.setAttribute('src', '//code.tidio.co/nyrw1o3knpob6ct9yidhrb0mtv8xiwen.js')
    tidioScript.onload = () => {
      window.tidioChatApi.on('ready', () => {
        this.handleTidioReady()
      })
      window.tidioChatApi.on('close', () => {
        this.hideTidio()
      })
      window.tidioChatApi.on('messageFromOperator', () => {
        if (!this.isOpen) this.notification = true
        this.showTidio()
      })
    }
    document.body.appendChild(tidioScript)
  },
  i18n: {
    messages: {
      'it-IT': {
        support: 'Richiedi assistenza',
        close: 'Chiudi chat'
      },
      en: {
        support: 'Ask for support',
        close: 'Close chat'
      },
      fr: {
        support: "Demander de l'aide",
        close: 'Fermer le chat'
      },
      es: {
        support: 'Solicita asistencia',
        close: 'Cerrar charla'
      }
    }
  },
  computed: {
    ...mapGetters({ modelId: 'modelId', userDetails: 'userDetails' })
  },
  methods: {
    handleTidioReady () {
      window.tidioChatApi.hide()
      this.isTidioActive = true
    },
    hideTidio () {
      this.toggleOverlay(false)
      window.tidioChatApi.hide()
      this.notification = false
      this.isOpen = false
    },
    showTidio () {
      this.toggleOverlay(true)
      window.tidioChatApi.show()
      window.tidioChatApi.popUpOpen()
      this.isOpen = true

      this.$gtag.event('open_chat', {
        event_category: this.isDealer ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    },
    toggleChat () {
      if (this.isOpen) {
        this.hideTidio()
        return
      }
      this.notification = false
      this.showTidio()
    },
    toggleOverlay (state = undefined) {
      this.$emit('toggle-overlay', state)
    }
  }
}
</script>
