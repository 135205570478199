<template>
  <SelectCard
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ $t('variant') }}
    </template>
    <Select
      v-for="variant, i in variants"
      :key="'var_' + i"
      :status="variant.id === selectedProduct.model ? 'selected' : 'active'"
      @click.native="changeModel(variant.tesyId)"
    >
      <strong>{{ variant.name }}</strong>
    </Select>
  </SelectCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SelectCard from 'ui/src/components/SelectCard'
import Select from 'ui/src/components/SelectCard/Select'
// import PictureSet from 'ui/src/components/PictureSet'

export default {
  name: 'VariantsCardMenu',
  components: { SelectCard, Select },
  i18n: {
    messages: {
      'it-IT': {
        variant: 'Varianti design'
      },
      en: {
        variant: 'Variants design'
      },
      fr: {
        variant: 'Variantes de conception'
      },
      es: {
        variant: 'Variantes de diseño'
      }
    }
  },
  props: {
    variants: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      imageSize: [
        {
          media: 1920,
          attr: {
            w: 720,
            h: 482,
            fit: 'fill'
          }
        },
        {
          media: 768,
          attr: {
            w: 600,
            h: 402,
            fit: 'fill'
          }
        },
        {
          media: 0,
          attr: {
            w: 380,
            h: 254,
            fit: 'fill'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ modelId: 'modelId', productGroup: 'productGroup', modelOptions: 'modelOptions', selectedProduct: 'selectedProduct' })
  },
  methods: {
    ...mapMutations({ setModelId: 'setModelId', setModelOptions: 'setModelOptions' }),
    changeModel (tesyId) {
      this.setModelId(tesyId)
    }
  }
}
</script>
