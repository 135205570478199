<template>
  <div class="SideEcommerce">
    <img
      v-if="screenshot"
      class="SideEcommerce__img"
      :src="`data:image/png;base64,${screenshot}`"
    >
    <div
      v-if="productName"
      class="SideEcommerce__product-name"
    >
      <span
        class="SideEcommerce__product-name__title"
      >{{ $t("product") }}:</span>
      {{ productName.value }}
    </div>
    <div
      v-if="measures"
      class="SideEcommerce__measures-container"
    >
      <span
        v-for="measure of measures"
        :key="measure.key"
        class="SideEcommerce__measure"
      ><component :is="measure.key" />{{ $t(`measures.${measure.key}`) }}:
        {{ measure.value }} CM
      </span>
    </div>
    <div>
      <div
        v-if="optionsSelected"
        class="SideEcommerce__options-label"
      >
        {{ $t("finishing") }}
      </div>
      <div
        v-if="optionsSelected"
        class="SideEcommerce__options-container"
      >
        <div
          v-for="option of optionsSelected"
          :key="option.key"
          class="SideEcommerce__option"
        >
          <div class="SideEcommerce__option__title">
            {{ option.key.toLowerCase() }}:
          </div>
          <div class="SideEcommerce__option__value">
            {{ option.value }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="price && showPrice"
      class="SideEcommerce__price"
    >
      <div class="SideEcommerce__price__container">
        <div class="SideEcommerce__price__label">
          {{ $t("price") }}
        </div>
        <div class="SideEcommerce__price__value">
          {{ getCurrency }} {{ priceIncrease ? Math.round(price + price*priceIncrease) * articleQty : Math.round(price) * articleQty}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ALT from './icons/ALT'
import LAR from './icons/LAR'
import PRF from './icons/PRF'

export default {
  components: {
    ALT,
    LAR,
    PRF
  },
  props: {
    screenshot: {
      type: String,
      default: null
    },
    optionsSelected: {
      type: Array,
      default: null
    },
    measures: {
      type: Array,
      default: null
    },
    price: {
      type: Number,
      default: null
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    productName: {
      type: Object,
      default: undefined
    },
    articleQty: {
      type: Number,
      default: 1
    }
  },
  i18n: {
    messages: {
      'it-IT': {
        measures: {
          LAR: 'Larghezza',
          PRF: 'Profondità',
          ALT: 'Altezza'
        },
        product: 'Prodotto',
        price: 'Prezzo',
        finishing: 'Finiture'
      },
      en: {
        measures: {
          LAR: 'Width',
          PRF: 'Depth',
          ALT: 'Height'
        },
        product: 'Product',
        price: 'Price',
        finishing: 'Finishes'
      },
      fr: {
        measures: {
          LAR: 'Longueur',
          PRF: 'Profondeur',
          ALT: 'Hauteur'
        },
        product: 'Produit',
        price: 'Prix',
        finishing: 'Finitions'
      },
      es: {
        measures: {
          LAR: 'Longitud',
          PRF: 'Profundidad',
          ALT: 'Altura'
        },
        product: 'Producto',
        price: 'Precio',
        finishing: 'Acabados'
      }
    }
  },
  computed: {
    isItalian () {
      return false
      // return this.$i18n.locale === 'it-IT'
    },
    getCurrency () {
      if (this.userDetails && this.userDetails.currency) {
        const mapping = {
          Euro: '€',
          Dollaro: '$',
          Pound: '£'
        }
        return mapping[this.userDetails.currency]
      } else {
        return '€'
      }
    },
    priceIncrease () {
      if (this.userDetails && this.userDetails.price_increase) {
        return Number(this.userDetails.price_increase) / 100
      } else {
        return null
      }
    },
    ...mapGetters({
      userDetails: 'userDetails'
    })
  }
}
</script>
<style lang="scss">
.SideEcommerce {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 6.25rem);
  padding: 0 20px 100px;
  box-sizing: border-box;
  overflow: auto;
  @media all and (min-width: 768px) {
    padding: 0px;
  }
  &__img {
    width: 100%;
  }
  &__product-name {
    font-size: 1rem;
    margin: 10px 0 2rem;
    &__title {
      font-weight: bold;
    }
  }
  &__measures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.125rem 0 4rem;
  }
  &__measure {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 12px 10px 0;
    svg {
      margin-right: 10px;
    }
  }
  &__options-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__option {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &__title {
      font-size: 10px;
      font-weight: bold;
      text-transform: capitalize;
    }
    &__value {
      font-size: 14px;
    }
  }
  &__options-label {
    font-weight: 700;
    font-size: 24px;
  }
  &__price {
    align-items: center;
    background-color: white;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    position: absolute;
    height: 6.25rem;
    width: 100%;
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
    }
    &__value {
      font-size: 40px;
    }
    @media all and (min-width: 768px) {
      display: none;
    }
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: #cecece #fff;
  }

  // For Google Chrome
  &::-webkit-scrollbar {
    width: 0.495rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #cecece;
    border-right: 0.175rem solid #fff;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: #cecece;
    scrollbar-track-color: #fff;
  }
}
</style>
