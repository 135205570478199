<template>
  <ModelsCard
    v-images-loaded="loadedImgsHandler"
    :overlap="isMobile"
    style="z-index: 1;"
    @close="$emit('close-products')"
    class="cancare"
  >
    <template v-slot:title>
      {{ $t('toolbar.models') }}
    </template>
    <template>
      <SingleProduct
        v-for="product of productsToShow"
        :key="product.fields.slug"
        :status="isActive(product.fields.models)"
        :clickable="isActive(product.fields.models) !== 'selected'"
        @select="select(product.fields.models[0].fields.tesyId)"
      >
        <template v-slot:image>
          <PictureSet
            v-if="product.fields.cover"
            :src="product.fields.cover.fields.file.url"
            :alt="product.fields.name"
            :title="product.fields.name"
            :size="imageSize"
          />
        </template>
        <template v-slot:label>
          {{ product.fields.name }}
        </template>
      </SingleProduct>
    </template>
    <div
      v-if="!showSkeleton"
      class="button-container"
    >
      <GenericButton
        v-if="(page !== total ) && total !== 0"
        type="secondary"
        :status="buttonStatus"
        @click.native="loadModels"
      >
        Carica altri prodotti
      </GenericButton>
    </div>
    <SkeletonTransition style="display: inherit;">
      <SkeletonModels v-if="showSkeleton" />
    </SkeletonTransition>
  </ModelsCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import imagesLoaded from 'vue-images-loaded'

import ModelsCard from 'ui/src/components/ModelsCard'
import SingleProduct from 'ui/src/components/SingleProduct'
import PictureSet from 'ui/src/components/PictureSet'
import SkeletonTransition from 'ui/src/components/Transitions/Skeleton'
import SkeletonModels from '../SkeletonModels'
import GenericButton from 'ui/src/components/Buttons/GenericButton'

export default {
  components: { ModelsCard, SingleProduct, PictureSet, SkeletonTransition, SkeletonModels, GenericButton },
  directives: {
    imagesLoaded
  },
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          models: 'Modelli'
        }
      },
      en: {
        toolbar: {
          models: 'Models'
        }
      },
      fr: {
        toolbar: {
          models: 'Modèles'
        }
      },
      es: {
        toolbar: {
          models: 'Modelos'
        }
      }
    }
  },
  data: () => ({
    isMobile: true,
    loading: true,
    selected: undefined,
    moreProductsLoaded: true,
    imgsLoaded: [],
    buttonStatus: 'active',
    showSkeleton: true,
    products: [],
    page: 1,
    total: 1,
    imageSize: [
      {
        media: 1920,
        attr: {
          w: 720,
          h: 482,
          fit: 'fill'
        }
      },
      {
        media: 768,
        attr: {
          w: 600,
          h: 402,
          fit: 'fill'
        }
      },
      {
        media: 0,
        attr: {
          w: 380,
          h: 254,
          fit: 'fill'
        }
      }
    ]
  }),
  computed: {
    ...mapGetters({ modelId: 'modelId', appViewerGetter: 'appViewerGetter' }),
    ...mapGetters('menu', { activeCards: 'activeCards' }),
    productsToShow () {
      if (window.innerWidth > 768) {
        return this.products.slice(0, 8 * this.page)
      }
      return this.products
    }
    /* showSkeleton () {
      return this.imgsLoaded.length < this.productsToShow.length
    } */
  },
  watch: {
    'activeCards.subcategory': {
      async handler (v) {
        const productEntry = await this.$contentful.getEntries({
          content_type: 'subcategory',
          'fields.slug': v,
          include: 3,
          locale: this.$i18n.locale
        })
        this.products = productEntry.items[0].fields.products
        this.total = Math.floor(this.products.length / 8)
        // if (window.innerWidth > 768) {
        //   this.productsToShow = productEntry.items[0].fields.products.slice(0, 8)
        //   this.productsToLoad = productEntry.items[0].fields.products.slice(8)
        // } else {
        //   this.productsToShow = productEntry.items[0].fields.products
        // }
      },
      immediate: true
    }
  },
  mounted () {
    this.isMobile = window.innerWidth < 768
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768
    })
  },
  methods: {
    ...mapMutations({ setModelId: 'setModelId', setModelOptions: 'setModelOptions' }),
    ...mapMutations('menu', { closeMenuCard: 'closeMenuCard' }),
    showProduct (name) {
      // if (!this.productsToLoad.length) return true
      return this.imgsLoaded.includes(name)
    },
    imageProgress (instance, image) {
      if (this.imgsLoaded.includes(image.img.currentSrc)) return
      this.imgsLoaded.push(image.img.currentSrc)
      this.buttonStatus = this.imgsLoaded.length === this.productsToShow.length ? 'active' : 'loading'
    },
    select (id) {
      this.setModelOptions({})
      this.setModelId(id)
      this.$emit('close-all')
    },
    loadModels () {
      if (this.page < this.total) {
        this.buttonStatus = 'loading'
        this.page++
      }
      // this.productsToShow = this.productsToShow.concat(this.productsToLoad.slice(0, 8))
      // this.productsToLoad = this.productsToLoad.slice(8)
    },
    isActive (models) {
      return models?.find((model) => model.fields.tesyId === this.modelId) ? 'selected' : 'active'
    },
    loadedImgsHandler (val) {
      this.showSkeleton = false
      this.buttonStatus = 'active'
    }
  }
}
</script>

<style lang="scss" scoped>

.cancare {
  padding-bottom: 2.5em;
}
.button-container {
  margin: 5.325vw 0;
  grid-column: 1/5;
  width: 100%;
  display: flex;
  justify-content: center;
  @media all and (max-width: 768px) {
    display: none;
  }
}
</style>
