<template>
  <SelectCard
    v-if="category.values"
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ category.name }}
    </template>
    <Select
      v-for="size in category.values"
      :key="size.v_valore"
      :status="size.c_valore === modelOptions[category.id] ? 'selected' : 'active'"
      @click.native="changeState(category.id, size.c_valore)"
    >
      {{ size.ds_valore }}
    </Select>
  </SelectCard>
</template>

<script>
import SelectCard from 'ui/src/components/SelectCard'
import Select from 'ui/src/components/SelectCard/Select'

import changeState from '../mixins/changeState'

export default {
  name: 'SelectCardMenu',
  components: { SelectCard, Select },
  mixins: [changeState],
  props: {
    category: {
      type: Object,
      required: true
    }
  }
}
</script>
