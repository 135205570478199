/* {
    icon: 'Orders',
    label: 'orders',
    to: '/orders'
}, */
export const dealerSections = [
  {
    icon: 'Projects',
    label: 'projects',
    to: '/projects'
  },
  {
    icon: 'Clients',
    label: 'clients',
    to: '/clients'
  },
  {
    icon: 'Account',
    label: 'account',
    to: '/user'
  }
]

export const privateUserSections = [
  {
    icon: 'Projects',
    label: 'projects',
    to: '/projects'
  },
  {
    icon: 'Account',
    label: 'account',
    to: '/user'
  }
]
