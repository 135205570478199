const zoomOuts = ['WEB_ARMNOW1', 'WEB_NOWQUICK', 'WEB_SMART']

export default {
  sceneConfigs: (appViewer, id) => {
    const scene = appViewer.engine3d.scene
    const forceZoomOut = zoomOuts.includes(id)

    // Camera settings
    const camera = scene.activeCamera
    camera.inputs.attached.pointers.buttons = [0]

    // Sensibility at mousewheel
    camera.wheelPrecision = 0.1
    camera.lowerRadiusLimit = 1500

    if (forceZoomOut) {
      camera.upperRadiusLimit = 7500
      appViewer.engine3d.zoomCamera(-5000)
    } else {
      camera.upperRadiusLimit = 3500
    }

    // scene.imageProcessingConfiguration.exposure = 1
    // scene.imageProcessingConfiguration.contrast = 2.2
  },
  sceneConfigsMobile: (appViewer, id) => {
    const scene = appViewer.engine3d.scene
    const forceZoomOut = zoomOuts.includes(id)

    // Camera settings
    const camera = scene.activeCamera
    camera.inputs.attached.pointers.buttons = [0]

    // Sensibility at mousewheel
    camera.wheelPrecision = 0.1
    camera.lowerRadiusLimit = 1500
    camera.upperRadiusLimit = 10000

    if (forceZoomOut) {
      appViewer.engine3d.zoomCamera(-5000)
    } else {
      appViewer.engine3d.zoomCamera(-4000)
    }

    // scene.imageProcessingConfiguration.exposure = 1
    // scene.imageProcessingConfiguration.contrast = 2.2
  }
}
