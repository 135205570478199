<template>
  <div
    v-if="isOpen('category') || isOpen('subcategory') || isOpen('product')"
    class="models-container"
  >
    <component
      :is="transitionType"
      :key="activeCards.subcategory"
    >
      <ProductsCard
        v-if="isOpen(['category', 'subcategory', 'product'])"
        ref="products"
        :style="{ zIndex: isMobile ? 3 : 1 }"
        :overlap="isMobile"
        @close-products="closeProducts"
        @close-all="closeAll"
        @select="closeAll"
      />
    </component>
    <component
      :is="transitionType"
      :key="activeCards.category"
    >
      <SubcategoryCard
        v-if="isOpen(['category', 'subcategory'])"
        :style="{ zIndex: 2 }"
        :overlap="isMobile"
        @open-products="openProducts"
      />
    </component>
    <component :is="transitionType">
      <CategoryCard
        v-if="isOpen('category')"
        :style="{ zIndex: isMobile ? 1 : 3 }"
        :current-category="selectedCategory"
        :overlap="isMobile"
        @open-subcategories="openSubcategories"
        @open-products="openProducts"
      />
    </component>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import CategoryCard from './CategoryCard'
import SubcategoryCard from './SubcategoryCard'
import ProductsCard from './ProductsCard'

import SlideRight from 'ui/src/components/Transitions/SlideRight'
import SlideUp from 'ui/src/components/Transitions/SlideUp'

export default {
  components: {
    CategoryCard,
    SubcategoryCard,
    ProductsCard,
    SlideRight,
    SlideUp
  },
  props: {
    transitionType: {
      type: String,
      required: true
    }
  },
  data: () => ({
    selectedCategory: undefined,
    selectedSubcategory: undefined,
    isMobile: true
  }),
  computed: {
    ...mapGetters('menu', { isOpen: 'isMenuCardOpen', activeCards: 'activeCards' })
  },
  mounted () {
    const checkIfIsMobile = () => {
      if (window.innerWidth > 768) {
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    }
    checkIfIsMobile()
    window.addEventListener('resize', () => {
      checkIfIsMobile()
    })
  },
  methods: {
    ...mapMutations('menu', { openMenuCard: 'openMenuCard', closeMenuCard: 'closeMenuCard', closeAll: 'closeAllMenuCards' }),
    openSubcategories (tesyId) {
      this.selectedCategory = tesyId
      this.closeMenuCard('product')
      this.openMenuCard(['category', 'subcategory'])
    },
    openProducts (slug) {
      if (this.$refs.products) {
        if (slug === this.$refs.products.subcategoryId) return
        this.$refs.products.loaded = false
        this.$refs.products.imgsLoaded = []
      }
      this.selectedSubcategory = slug
      this.openMenuCard(['category', 'subcategory', 'product'])
    },
    async closeProducts () {
      this.closeMenuCard('product')
      const categoryActive = await this.$contentful.getEntries({
        content_type: 'category',
        'fields.tesyId': this.selectedCategory,
        limit: 1,
        locale: this.$i18n.locale
      })
      if (categoryActive.items[0].fields.subcategories.length > 1) return
      this.closeMenuCard('subcategory')
    }
  }
}
</script>

<style lang="scss" scoped>
.models-container {
  position: relative;
  display: flex;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
}
</style>
