import Vue from 'vue'
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: {
    id: 'G-MSEGZPEMHR',
    params: {
      send_page_view: false
    }
  }
})
