<template>
  <SocialSharing
    v-if="url"
    :url="url"
    @shared="handleShared"
  >
    <template v-slot:label>
      {{ $t('label') }}
    </template>
    <template v-slot:toast>
      {{ $t('toast') }}
    </template>
  </SocialSharing>
</template>

<script>
import SocialSharing from 'ui/src/components/SocialSharing'
import { mapGetters } from 'vuex'

export default {
  i18n: {
    messages: {
      'it-IT': {
        label: 'Condividi',
        toast: 'Link copiato con successo'
      },
      en: {
        label: 'Share',
        toast: 'Link copied successfully'
      },
      fr: {
        label: 'Partager',
        toast: 'Lien copié avec succès'
      },
      es: {
        label: 'Cuota',
        toast: 'Enlace copiado correctamente'
      }
    }
  },
  components: {
    SocialSharing
  },
  computed: {
    ...mapGetters({ url: 'url', userDetails: 'userDetails' })
  },
  methods: {
    handleShared () {
      this.$gtag.event('Configuratore [Condivido]', {
        event_category: 'configuratore',
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    }
  }
}
</script>
