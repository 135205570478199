<script>
import { mapMutations, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  computed: {
    ...mapGetters({ appViewerGetter: 'appViewerGetter', articleId: 'articleId' })
  },
  watch: {
    '$route.query.options': {
      handler (value) {
        if (JSON.stringify(value) === this.modelOptions) return
        this.setModelOptions(value)
      },
      immediate: true
    },
    // '$route.query.token': {
    //   handler () {
    //     this.setToken(this.$route.query.token)
    //   },
    //   immediate: true
    // },
    '$route.query.model': {
      async handler () {
        this.setModelId(this.$route.query.model)

        try {
          const info = await axios.get('https://configurator.lago.it/entries/' + this.$i18n.locale + '/' + this.$route.query.model.toUpperCase() + '.json')

          const groupEntry = await this.$contentful.getEntries({
            content_type: 'group',
            links_to_entry: info.data.product,
            limit: 1,
            include: 2,
            locale: this.$i18n.locale
          })

          if (info.data.product && groupEntry.total !== 0) {
            await this.setProductGroup(groupEntry.items[0].fields)
          } else {
            await this.setProductGroup(null)
          }

          const productEntry = await this.$contentful.getEntries({
            content_type: 'product',
            'sys.id': info.data.product,
            limit: 1,
            include: 1,
            locale: this.$i18n.locale
          })
          const prod = productEntry.items[0].fields
          if (prod.editAll) {
            this.setEditAll(true)
          } else {
            this.setEditAll(false)
          }
          this.setSelectedProduct(info.data)
        } catch (error) {
          const modelEntry = await this.$contentful.getEntries({
            content_type: 'model',
            'fields.tesyId': this.$route.query.model.toUpperCase(),
            locale: this.$i18n.locale
          })
          const modelId = modelEntry.items[0].sys.id

          const productEntry = await this.$contentful.getEntries({
            content_type: 'product',
            links_to_entry: modelId,
            limit: 1,
            include: 1,
            locale: this.$i18n.locale
          })
          const productId = productEntry.items[0].sys.id

          const prod = productEntry.items[0].fields

          if (prod.editAll) {
            this.setEditAll(true)
          } else {
            this.setEditAll(false)
          }

          const subcategoryEntry = await this.$contentful.getEntries({
            content_type: 'subcategory',
            links_to_entry: productId,
            limit: 1,
            include: 1,
            locale: this.$i18n.locale
          })
          const subcategoryId = subcategoryEntry.items[0].sys.id

          const groupEntry = await this.$contentful.getEntries({
            content_type: 'group',
            links_to_entry: productId,
            limit: 1,
            include: 2,
            locale: this.$i18n.locale
          })

          if (groupEntry.total !== 0) {
            await this.setProductGroup(groupEntry.items[0].fields)
          } else {
            await this.setProductGroup(null)
          }

          // const subcategoryId = subcategoryEntry.items[0].sys.id

          const categoryEntry = await this.$contentful.getEntries({
            content_type: 'category',
            links_to_entry: subcategoryId,
            limit: 1,
            include: 1,
            locale: this.$i18n.locale
          })
          const categoryId = categoryEntry.items[0].sys.id

          this.setSelectedProduct({
            model: modelId,
            product: productId,
            subcategory: subcategoryId,
            category: categoryId
          })
        }
      },
      immediate: true
    },
    articleId: {
      handler () {
        if (!this.appViewer.getElementSelected()) return
        if (Object.keys(this.$route.query.options).length > 0) {
          console.info('Setting options from url.')
          this.setModelOptions(this.$route.query.options)
          return
        }
        console.info('No options found. Get default options.')
        const options = this.appViewer.getElementSelected().categorie
          .filter((cat) => cat.Valori.length > 1)
          .reduce((obj, item) => Object.assign(obj, { [item.c_categoria]: item.c_valore_scelto }), {})
        this.setModelOptions(options)
      }
    }
  },
  methods: {
    ...mapMutations({ setModelId: 'setModelId', setModelOptions: 'setModelOptions', setSelectedProduct: 'setSelectedProduct', setToken: 'setToken', setProductGroup: 'setProductGroup', setEditAll: 'setEditAll' })
  },
  render: () => ''
}
</script>
