<template>
  <Utility
    icon="Reset"
    :status="status"
    @click.native="reset"
  >
    {{ $t('reset') }}
  </Utility>
</template>

<script>
import Utility from 'ui/src/components/Utilities/Utility'

import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Utility
  },
  i18n: {
    messages: {
      'it-IT': {
        reset: "Ricomincia dall'inizio"
      },
      en: {
        reset: 'Start again'
      },
      fr: {
        reset: 'Recommencer'
      },
      es: {
        reset: 'Empezar de nuevo'
      }
    }
  },
  data: () => ({
    edits: 0
  }),
  computed: {
    ...mapGetters({ modelOptions: 'modelOptions', modelId: 'modelId', userDetails: 'userDetails' }),
    status: {
      get () {
        return this.edits > 1 ? 'active' : 'disabled'
      }
    }
  },
  watch: {
    modelOptions: {
      handler (value, oldValue) {
        if (JSON.stringify(value) === JSON.stringify(oldValue)) {
          return
        }

        if (Object.keys((value)) <= 0) return

        this.edits = this.edits + 1
      }
    }
  },
  methods: {
    ...mapMutations({ setModelOptions: 'setModelOptions' }),
    reset () {
      this.$router.replace({ query: { model: this.modelId, options: {} } })
      this.$gtag.event('reset_edit', {
        event_category: this.isDealer ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
      window.location.reload()
    }
  }
}
</script>
