<template>
  <UserToggle
    :is-logged="isLogged"
    :user-details="isLogged ? userDetails : null"
    @logButtonClicked="toggleSidebar"
  >
    <template
      v-slot:userModule
    >
      <SlideRight>
        <UserSidebar
          v-if="showUserSidebar"
          @close="toggleSidebar"
        >
          <template
            v-slot:sidebar-slot
          >
            <LoginModule
              v-if="!isLogged"
              :content="mockData.logModuleContent"
              @login="handleLogin"
            />
            <UserDetailsModule
              v-if="isLogged"
              @logout="handleLogOut"
            >
              <template v-slot:greetings>
                {{ userDetails.role === 'Architect' ? userDetails.company : $t('userModule.greetings', { name: userDetails.name }) }}
              </template>
              <template v-slot:role>
                {{ $t('userModule.roleLabel', { role: userRole }) }}
              </template>
              <template v-slot:sections>
                <SectionButton
                  v-for="section in userSections"
                  :key="section.icon"
                  :icon-type="section.icon"
                  @click.native="handleNavigation(section)"
                >
                  {{ $t(`menuLabels.${section.label}`) }}
                </SectionButton>
                <!-- <div @click="test">TEST LOCALE</div> -->
              </template>
              <template v-slot:logout>
                {{ $t('userModule.logout') }}
              </template>
            </UserDetailsModule>
          </template>
        </UserSidebar>
      </SlideRight>
    </template>
  </UserToggle>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LoginModule from 'ui/src/components/LoginModule'
import SectionButton from 'ui/src/components/Buttons/SectionButton'
import UserToggle from 'ui/src/components/UserToggle'
import UserSidebar from 'ui/src/components/UserSidebar'
import UserDetailsModule from 'ui/src/components/UserDetailsModule'
import SlideRight from 'ui/src/components/Transitions/SlideRight'

import { privateUserSections, dealerSections } from '@/assets/configurations/user-sections-allowance.js'
export default {
  components: {
    LoginModule,
    SectionButton,
    SlideRight,
    UserDetailsModule,
    UserToggle,
    UserSidebar
  },
  props: {
    userDetails: {
      type: Object,
      required: false,
      default: null
    }
  },
  i18n: {
    messages: {
      it: {
        userModule: {
          greetings: 'Ciao {name}, bentornato!',
          roleLabel: 'Ruolo {role}',
          logout: 'Disconnetti'
        },
        menuLabels: {
          projects: 'I miei progetti',
          orders: 'Ordini inviati',
          clients: 'Anagrafica clienti',
          account: 'Il mio account'
        }
      },
      en: {
        userModule: {
          greetings: 'Hi {name}, welcome back!',
          roleLabel: 'Role {role}',
          logout: 'Log out'
        },
        menuLabels: {
          projects: 'My projects',
          orders: 'Orders',
          clients: 'Clients',
          account: 'My account'
        }
      },
      fr: {
        userModule: {
          greetings: 'Salut {name}, bienvenue à nouveau!',
          roleLabel: 'Ruolo {role}',
          logout: 'Se déconnecter'
        },
        menuLabels: {
          projects: 'Mes projets',
          orders: 'Commandes envoyées',
          clients: 'Clients',
          account: 'Mon compte'
        }
      },
      es: {
        userModule: {
          greetings: 'Hola {name}, bienvenido de nuevo!',
          roleLabel: 'Ruolo {role}',
          logout: 'Cerrar sesión'
        },
        menuLabels: {
          projects: 'Mis proyectos',
          orders: 'Pedidos enviados',
          clients: 'Clientes',
          account: 'Mi cuenta'
        }
      }
    }
  },
  data () {
    return {
      dealerSections,
      privateUserSections,
      mockData: {
        logModuleContent: {
          intro: 'Accedi',
          dida: 'Registrati o Accedi per accedere a funzioni e vantaggi pensati apposta per te.'
        }
      },
      showUserSidebar: false
    }
  },
  computed: {
    ...mapGetters({ appViewerGetter: 'appViewerGetter' }),
    userSections () {
      if (this.isDealer) {
        return this.dealerSections
      } else {
        return this.privateUserSections
      }
    },
    isLogged () {
      return !!this.userDetails
    },
    isDealer () {
      return true
    },
    userRole () {
      return this.userDetails && this.userDetails.role ? this.userDetails.role : ''
    },
    ...mapGetters({ appViewerGetter: 'appViewerGetter' })
  },
  methods: {
    toggleSidebar () {
      this.showUserSidebar = !this.showUserSidebar
    },
    handleNavigation (obj) {
      parent.postMessage({ type: 'navigation', id: 'navigation request', options: { to: obj.to, configuration: { model: this.$route.query.model, options: this.$route.query.options } } }, '*')
    },
    handleLogin () {
      parent.postMessage({ type: 'loginFromConfigurator', id: 'navigation request', options: { configuration: { model: this.$route.query.model, options: this.$route.query.options } } }, '*')
      this.$gtag.event('click_login')
    },
    test () {
      parent.postMessage({ type: 'changeLocale', id: 'change locale', options: {} }, '*')
    },
    async handleLogOut () {
      await this.appViewerGetter().logout()
      this.logOut()
      parent.postMessage({ type: 'logoutFromConfigurator', id: 'logout request', options: { } }, '*')
    },
    ...mapMutations({ logOut: 'logOut' })
  }
}
</script>

<style></style>
