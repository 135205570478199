<template>
  <div class="EcommerceRequest">
    <PrivateAreaSidebar>
      <div class="EcommerceRequest__side">
        <BackToConfigurator
          :label="$t('back')"
          @click.native="handleClose"
        />
        <SideEcommerce
          :screenshot="screenshot"
          :options-selected="optionsSelected"
          :price="price"
          :measures="measures"
          :product-name="productName"
          :show-price="Boolean(user && user.sf_access_token)"
          :article-qty="Number(articleQty)"
        />
      </div>
    </PrivateAreaSidebar>
    <div class="EcommerceRequest__body">
      <div class="EcommerceRequest__logo">
        <Logo />
      </div>
      <div class="EcommerceRequest__close-mobile">
        <BackToConfigurator
          :label="$t('back')"
          @click.native="handleClose"
        />
      </div>
      <SectionsTitle :title="user && canUserSendDealerOrders ? $t('request') : $t('infoRequest')" />
      <!-- TO FIX IS DEALER -->
      <div class="EcommerceRequest__quantity" v-if="user && canUserSendDealerOrders">
        <div class="EcommerceRequest__quantity__label">Quantità articolo</div>
        <div class="EcommerceRequest__quantity__container">
          <input
          id="quantity"
          v-model="articleQty"
          class="EcommerceRequest__quantity__input"
          type="number"
          name="quantity"
          min="1"
          >
        </div>
      </div>
      <AnagraficaEcommerce
        :options-selected="formInfos"
        :price="price"
        :measures="measures"
        :article-qty="Number(articleQty)"
        v-if="user && canUserSendDealerOrders"
      />
      <AnagraficaEcommerceNotLogged
        :options-selected="formInfos"
        :price="price"
        :measures="measures"
        :role="userRole"
        v-else
      />
    </div>
    <div class="EcommerceRequest__mobile-recap" :class="{'is-open': isOpen}">
      <div class="EcommerceRequest__mobile-recap__toggle">
        <h3 class="EcommerceRequest__mobile-recap__label" @click="isOpen = !isOpen">
          {{ $t('recap') }}
        </h3>
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="EcommerceRequest__mobile-recap__icon" :class="{'is-open': isOpen}">
          <path d="M1 5.61607L5.49999 1L9.99998 5.61608" stroke="#242424" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5.50009 1.83485L5.50009 12.2428" stroke="#242424" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <SideEcommerce
          :screenshot="screenshot"
          :options-selected="optionsSelected"
          :price="price"
          :measures="measures"
          :product-name="productName"
          :show-price="Boolean(user && user.sf_access_token)"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* import i18n from './i18n' */
import PrivateAreaSidebar from '~/../ui/src/components/PrivateAreaSidebar'
import SideEcommerce from './SideEcommerce.vue'
import AnagraficaEcommerce from './AnagraficaEcommerce.vue'
import BackToConfigurator from '~/../ui/src/components/PrivateArea/BackToConfigurator'
import SectionsTitle from '~/../ui/src/components/PrivateArea/SectionsTitle'
import Logo from '~/../ui/src/components/Logo'
import AnagraficaEcommerceNotLogged from './AnagraficaEcommerceNotLogged.vue'

export default {
  components: {
    PrivateAreaSidebar,
    SideEcommerce,
    AnagraficaEcommerce,
    BackToConfigurator,
    SectionsTitle,
    Logo,
    AnagraficaEcommerceNotLogged
  },
  props: ['user'],
  filters: {
    formattedPrice (p, locale) {
      const toCurrency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR'
      })
      return toCurrency.format(p)
    }
  },
  i18n: {
    messages: {
      'it-IT': {
        back: 'Torna indietro',
        request: 'Richiesta d\'acquisto',
        infoRequest: 'Richiedi di acquistare',
        recap: 'Riepilogo'
      },
      en: {
        back: 'Go back',
        request: 'Purchase Request',
        infoRequest: 'Product information request',
        recap: 'Recap'
      },
      fr: {
        back: 'Revenir',
        request: 'Demande d\'achat',
        infoRequest: 'Demande d\'information sur le produit',
        recap: 'Recap'
      },
      es: {
        back: 'Regresar',
        request: 'Solicitud de compra',
        infoRequest: 'Solicitud de información del producto',
        recap: 'Recap'
      }
    }
  },
  data: () => ({
    currentStep: 1,
    id: null,
    loading: false,
    error: false,
    form: undefined,
    isOpen: false,
    articleQty: 1
  }),
  computed: {
    ...mapGetters({ appViewerGetter: 'appViewerGetter', screenshot: 'screenshot', modelId: 'modelId', modelOptions: 'modelOptions', url: 'url' }),
    formValidator: {
      get () {
        return this.$refs.form.$refs.validator
      }
    },
    price: {
      get () {
        if (!this.appViewerGetter || typeof this.appViewerGetter !== 'function') return null
        return this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
        // return this.appViewerGetter().getElementSelected().articoloGemo.elemento.PrezzoListino.n_prezzo_listino
      }
    },
    optionsSelected: {
      get () {
        if (!this.modelOptions || !this.appViewerGetter()) return undefined
        return [].concat(
          this.appViewerGetter().getElementSelected().articoloGemo.categorie
            .filter((cat) => Object.keys(this.modelOptions).includes(cat.c_categoria))
            .filter((cat) => cat.fg_non_obbligatorio === 0)
            .map((cat) => {
              const value = cat.Valori.find((val) => val.c_valore === cat.c_valore_scelto) // .ds_valore
              return {
                key: cat.ds_categoria,
                value: value ? value.ds_valore : ''
              }
            })
        )
      }
    },
    formInfos: {
      get () {
        if (!this.modelOptions || !this.appViewerGetter()) return undefined
        return [{
          key: 'PRODOTTO',
          value: this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
        }].concat(
          this.appViewerGetter().getElementSelected().articoloGemo.categorie
            .filter((cat) => Object.keys(this.modelOptions).includes(cat.c_categoria))
            .filter((cat) => cat.fg_non_obbligatorio === 0)
            .map((cat) => {
              const value = cat.Valori.find((val) => val.c_valore === cat.c_valore_scelto) // .ds_valore
              return {
                key: cat.ds_categoria,
                value: value ? value.ds_valore : ''
              }
            })
        )
      }
    },
    productName () {
      if (!this.modelOptions || !this.appViewerGetter()) return undefined
      return {
        key: 'PRODOTTO',
        value: this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
      }
    },
    measures () {
      if (!this.modelOptions || !this.appViewerGetter()) return undefined
      const pairs = [
        { key: 'c_sigla_dim_1', value: 'n_dim_1' },
        { key: 'c_sigla_dim_2', value: 'n_dim_2' },
        { key: 'c_sigla_dim_3', value: 'n_dim_3' }
      ]
      const measuresArray = []
      const measuresInfos = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo
      pairs.forEach(d => {
        const measureObj = {}
        measureObj.key = measuresInfos[d.key]
        measureObj.value = measuresInfos[d.value]
        measuresArray.push(measureObj)
      })
      return measuresArray
    },
    details: {
      get () {
        return this.optionsSelected
          .map(detail => `<strong>${detail.key}</strong>: ${detail.value}`)
          .join('<br/>')
      }
    },
    userRole () {
      return this.user && this.user.role ? this.user.role.toLowerCase() : 'NO_ROLE'
    },
    canUserSendDealerOrders () {
      // messo questo controllo, nel caso i dealer fossero senza ruolo
      const blockedRoles = ['architect', 'private']
      return !blockedRoles.includes(this.userRole)
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleFormChange (form) {
      this.form = form
      this.submitLead('New')
    }
  }
}
</script>
<style lang="scss">
.EcommerceRequest{
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  &__quantity {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: 100px;
      background-color: white;
      border-radius: 15px;
      font-size: 40px;
      font-weight: 500;
      justify-content: center;
    }
    &__input {
      border:none;
      background: transparent;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      &:focus{
        outline: none;
      }
    }
  }
  &__body {
    overflow: auto;
    width: 100%;
    position: relative;
    padding-bottom: 180px;
    @media all and (min-width: 768px) {
      padding: 30px 60px 30px;
      width: calc(100% - 26rem);
    }
    @media all and (min-width: 1600px) {
      width: calc(100% - 30rem);
      padding: 40px 136px 0;
    }
  }
  &__close-mobile {
    @media all and (min-width: 768px) {
     display: none;
    }
  }
  &__side {
    height: 100%;
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
    position: relative;
  }
  &__logo {
    max-width: 150px;
    img {
      max-width: 100%;
    }
    @media all and (min-width: 768px) {
      display: none;
    }
  }
  &__mobile-recap {
    @media all and (min-width: 768px) {
      display: none;
    }
    height: 90vh;
    width: 100vw;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: fixed;
    bottom: -78vh;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 100;
    transition: all 0.4s ease;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.08);
    &.is-open {
      bottom: 0;
    }
    &__label {
      font-size: 20px;
      margin-right: 8px;
    }
    &__icon {
      &.is-open {
        transform: rotate(180deg);
      }
    }
    &__toggle {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 20px 0 30px;
    }
  }
}
</style>
