<template>
  <InspirationsCard
    v-if="presets"
    @close="close('inspirations')"
  >
    <template v-slot:title>
      {{ $t('toolbar.inspirations.inner') }}
    </template>
    <SingleProduct
      v-for="preset in presets"
      :key="preset.sys.id"
      :status="'active'"
      @select="select(preset.fields.options)"
    >
      <template v-slot:image>
        <PictureSet
          v-if="preset.fields.picture"
          :src="preset.fields.picture.fields.file.url"
          :alt="preset.fields.picture.fields.file.url"
          :title="preset.fields.picture.fields.file.url"
          :size="imageSize"
        />
      </template>
      <template v-slot:label>
        {{ preset.fields.name }}
      </template>
    </SingleProduct>
  </InspirationsCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import qs from 'qs'

import PictureSet from 'ui/src/components/PictureSet'
import InspirationsCard from 'ui/src/components/InspirationsCard'
import SingleProduct from 'ui/src/components/SingleProduct'

export default {
  components: { InspirationsCard, SingleProduct, PictureSet },
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          inspirations: {
            inner: 'Alcune idee di configurazione'
          }
        }
      },
      en: {
        toolbar: {
          inspirations: {
            inner: 'Configuration ideas'
          }
        }
      },
      fr: {
        toolbar: {
          inspirations: {
            inner: 'Quelques idées de configuration'
          }
        }
      },
      es: {
        toolbar: {
          inspirations: {
            inner: 'Algunas ideas de configuración'
          }
        }
      }
    }
  },
  data: () => ({
    presets: undefined,
    imgsLoaded: [],
    imageSize: [
      {
        media: 1920,
        attr: {
          w: 720,
          h: 482,
          fit: 'fill'
        }
      },
      {
        media: 768,
        attr: {
          w: 600,
          h: 402,
          fit: 'fill'
        }
      },
      {
        media: 0,
        attr: {
          w: 380,
          h: 254,
          fit: 'fill'
        }
      }
    ]
  }),
  computed: {
    ...mapGetters({ modelId: 'modelId' })
  },
  watch: {
    modelId: {
      async handler () {
        if (!this.modelId) return
        const presets = await this.$contentful.getEntries({
          content_type: 'preset',
          include: 10,
          locale: this.$i18n.locale
        })
        this.presets = presets.items.filter((preset) => preset.fields.model.fields.tesyId === this.modelId)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({ setModelOptions: 'setModelOptions' }),
    ...mapMutations('menu', { close: 'closeMenuCard', closeAll: 'closeAllMenuCards' }),
    select (options) {
      this.setModelOptions(qs.parse(options).options)
      this.closeAll()
    }
  }
}
</script>
