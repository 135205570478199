<template>
  <Container>
    <ProductSkeleton
      v-for="n in 8"
      :key="n"
    />
  </Container>
</template>

<script>
import ProductSkeleton from 'ui/src/components/Skeleton/Navigation/Product'
import Container from 'ui/src/components/Skeleton/Navigation'

export default {
  components: { ProductSkeleton, Container }
}
</script>
