<template>
  <CustomizationsCard
    v-if="availableConfigurations"
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ $t('toolbar.customizations.main') }}
    </template>
    <Customization
      v-if="productGroup"
      @click.native="$emit('open', 'support')"
    >
      <template v-slot:icon>
        <Support />
      </template>
      <template v-slot:label>
        {{ $t('toolbar.support') }}
      </template>
    </Customization>
    <Customization
      v-if="hasSiblings"
      @click.native="$emit('open', 'siblings')"
    >
      <template v-slot:icon>
        <Size />
      </template>
      <template v-slot:label>
        {{ $t('toolbar.customizations.sizes') }}
      </template>
    </Customization>
    <Customization v-if="isMultiLevel">
      <template v-slot:label>
        <SwitcherC
          :selected="pro"
          @toggle="setPro(!pro)"
        />
        {{ $t('toolbar.pro_mode') }}
      </template>
    </Customization>
    <Customization
      v-if="hasLevels"
      @click.native="$emit('open', 'dimLevels')"
    >
      <template v-slot:icon>
        <Size />
      </template>
      <template v-slot:label>
        {{ $t('toolbar.customizations.sizes') }}
      </template>
    </Customization>
    <Customization
      v-if="hasVariants"
      @click.native="$emit('open', 'variants')"
    >
      <template v-slot:icon>
        <Variant />
      </template>
      <template v-slot:label>
        {{ $t('toolbar.variants') }}
      </template>
    </Customization>
    <Customization
      v-for="option of transformedConfigurations"
      :key="option.id"
      @click.native="handleCustomizationClick(option)"
    >
      <template v-slot:icon>
        <ImageC
          v-if="option.type === 'image' || option.type === 'list'"
          :color="getSelectedColor(option)"
        >
          <img
            v-if="getSelectedImage(option)"
            :src="getSelectedImage(option)"
          >
        </ImageC>
        <SelectC
          v-if="option.type === 'select'"
          :code="option.id"
        />
        <Switcher
          v-if="option.type === 'switcher'"
          :option="option"
        />
      </template>
      <template v-slot:label>
        {{ option.name }}
      </template>
    </Customization>
    <Customization
      v-if="presets && presets.length > 0"
      key="inspirations"
      @click.native="$emit('open', 'inspirations')"
    >
      <template v-slot:icon>
        <Inspirations />
      </template>
      <template v-slot:label>
        {{ $t('toolbar.inspirations.main') }}
      </template>
    </Customization>
  </CustomizationsCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CustomizationsCard from 'ui/src/components/CustomizationsCard'
import Customization from 'ui/src/components/CustomizationsCard/Customization'
import ImageC from 'ui/src/components/CustomizationsCard/Types/Image'
import Size from 'ui/src/components/CustomizationsCard/Types/Size'
import Support from 'ui/src/components/CustomizationsCard/Types/Support'
import Variant from 'ui/src/components/CustomizationsCard/Types/Variant'
import SelectC from 'ui/src/components/CustomizationsCard/Types/Select'
import Switcher from '../Switcher'
import SwitcherC from 'ui/src/components/CustomizationsCard/Types/Switcher'
import Inspirations from 'ui/src/components/CustomizationsCard/Types/Inspiration'

export default {
  components: {
    Customization,
    CustomizationsCard,
    ImageC,
    Size,
    Support,
    Variant,
    SelectC,
    Switcher,
    SwitcherC,
    Inspirations
  },
  props: {
    zIndex: {
      type: Number,
      default: () => 1
    },
    hasSiblings: {
      type: Boolean,
      required: true
    },
    hasVariants: {
      type: Boolean,
      required: false,
      default: false
    },
    hasLevels: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          inspirations: {
            main: 'Trova ispirazione'
          },
          customizations: {
            main: 'Personalizzazioni',
            sizes: 'Dimensioni'
          },
          support: 'APPOGGIO',
          variants: 'VARIANTI',
          applyAll: 'APPLICA ULTIMA SCELTA A TUTTI',
          pro_mode: 'Modalità PRO'
        }
      },
      en: {
        toolbar: {
          inspirations: {
            main: 'I need inspiration'
          },
          customizations: {
            main: 'Customisation options',
            sizes: 'Size'
          },
          support: 'SUPPORT',
          variants: 'VARIANTS',
          applyAll: 'APPLICA ULTIMA SCELTA A TUTTI',
          pro_mode: 'Modalità PRO'
        }
      },
      fr: {
        toolbar: {
          inspirations: {
            main: 'Trouver l’inspiration'
          },
          customizations: {
            main: 'Personnalisations',
            sizes: 'Dimensions'
          },
          support: 'SUPPORT',
          variants: 'VARIANTES',
          applyAll: 'APPLICA ULTIMA SCELTA A TUTTI',
          pro_mode: 'Modalità PRO'
        }
      },
      es: {
        toolbar: {
          inspirations: {
            main: 'Encuentra inspiración'
          },
          customizations: {
            main: 'Personalizaciones',
            sizes: 'Dimensiones'
          },
          support: 'APPOYO',
          variants: 'VARIANTES',
          applyAll: 'APPLICA ULTIMA SCELTA A TUTTI',
          pro_mode: 'Modalità PRO'
        }
      }
    }
  },
  data: () => ({
    presets: undefined
  }),
  computed: {
    ...mapGetters({
      availableConfigurations: 'availableConfigurations',
      modelId: 'modelId',
      modelOptions: 'modelOptions',
      appViewerGetter: 'appViewerGetter',
      productGroup: 'productGroup',
      lastChange: 'lastChange',
      isMultiLevel: 'isMultiLevel',
      pro: 'pro'
    }),
    getSelectedColor: {
      get () {
        return option => {
          const selected = option.type === 'list'
            ? option.values
              .map((opt) => opt.values)
              .flat()
              .find((val) => val.c_valore === this.modelOptions[option.id])
            : option.values
              .find((val) => val.c_valore === this.modelOptions[option.id])
          if (selected) return `rgb(${selected.n_R}, ${selected.n_G}, ${selected.n_B})`

          // const category = this.appViewerGetter().getElementSelected().categorie
          const category = this.appViewerGetter().element_selected.articoloGemo.categorie
            .find((cat) => cat.c_categoria === option.id)
          if (!category) return 'rgb(0, 0, 0)'

          const value = category.Valori.find((val) => val.c_valore === category.c_valore_scelto)
          if (!value) return 'rgb(0, 0, 0)'
          return `rgb(${value.n_R}, ${value.n_G}, ${value.n_B})`
        }
      }
    },
    getSelectedImage: {
      get () {
        return option => {
          const selected = option.values
            .find((val) => val.c_valore === this.modelOptions[option.id])
          if (selected) return selected.imageUrl

          // const category = this.appViewerGetter().getElementSelected().categorie
          const category = this.appViewerGetter().element_selected.articoloGemo.categorie
            .find((cat) => cat.c_categoria === option.id)
          if (!category) return ''

          const value = category.Valori.find((val) => val.c_valore === category.c_valore_scelto)
          if (!value) return ''

          return value.imageUrl
        }
      }
    },
    transformedConfigurations () {
      let confs = this.availableConfigurations
      confs = confs.map(conf => {
        if (conf.id === 'TSD' && conf.type === 'image') {
          conf.type = 'select'
        }
        if (conf.id === 'ALTST' && conf.type === 'image') {
          conf.type = 'select'
        }
        if (conf.id === 'ALTCAS' && conf.type === 'image') {
          conf.type = 'select'
        }
        if (conf.id === 'ACC' && conf.type === 'image') {
          conf.type = 'select'
        }
        return conf
      })
      return confs
    }
  },
  watch: {
    modelId: {
      async handler () {
        if (!this.modelId) return
        const presets = await this.$contentful.getEntries({
          content_type: 'preset',
          include: 10,
          locale: this.$i18n.locale
        })
        this.presets = presets.items.filter((preset) => preset.fields.model.fields.tesyId === this.modelId)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({ setModelOptions: 'setModelOptions', setPro: 'setPro' }),
    handleCustomizationClick (option) {
      if (option.type === 'switcher') return
      this.$emit('open', option.id)
    },
    editAll () {
      const lastChange = this.lastChange
      this.setModelOptions({ FAS1: lastChange, FAS2: lastChange, FAS3: lastChange, FAS4: lastChange, FAS5: lastChange, FAS6: lastChange, FAS7: lastChange, FAS8: lastChange, FAS9: lastChange, FAS10: lastChange, FAS11: lastChange, FAS12: lastChange, FIAR1: lastChange, FIAR2: lastChange, FIAF1: lastChange, FIAF2: lastChange })
    }
  }
}
</script>
