<template>
  <div class="main">
    <TopBar />
    <UserModule :user-details="userDetails" />
    <Utilities @toggle-overlay="handleToggleOverlay" />
    <MainNavigation
      ref="mainNavigation"
      @open-fake-ecommerce="setShowCheckoutForm(true)"
    />
    <Object3d />
    <RulesController />
    <LocaleController @change="onLocaleChange" />
    <UrlController />
    <MessageController />
    <FirstTestController />
    <ObjectController
      :model="model"
      options="options"
    />
    <EcommerceRequest
      v-if="showCheckoutForm"
      @close="setShowCheckoutForm(false)"
      :user="userDetails"
    />
    <Loader v-if="showLoader" />
    <FadeIn>
      <div
        v-if="showOverlay"
        class="overlay"
      />
    </FadeIn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import Object3d from '@/components/Object3d'
import TopBar from '@/components/TopBar'
import MainNavigation from '@/components/MainNavigation'
import UrlController from '@/components/UrlController'
import LocaleController from '@/components/LocaleController'
import RulesController from '@/components/RulesController'
import MessageController from '@/components/MessageController'
import ObjectController from '@/components/ObjectController'
import FirstTestController from '@/components/FirstTestController'
import Loader from 'ui/src/components/Loader'
import FadeIn from 'ui/src/components/Transitions/FadeIn'
import Utilities from '@/components/Utilities'
import EcommerceRequest from '@/components/EcommerceRequest'
import UserModule from '@/components/UserModule'
// import TestSaveConf from '@/components/TestSaveConf'
export default {
  name: 'Index',
  components: {
    Object3d,
    TopBar,
    MainNavigation,
    UrlController,
    RulesController,
    LocaleController,
    MessageController,
    ObjectController,
    FirstTestController,
    Loader,
    FadeIn,
    Utilities,
    EcommerceRequest,
    UserModule
    // TestSaveConf
  },
  props: {
    model: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    },
    token: {
      type: String
    }
  },
  data: () => ({
    showLoader: true,
    showOverlay: false,
    tryToOpenOrderForm: false
  }),
  computed: {
    test () {
      return this.$t
    },
    ...mapGetters({ loader: 'loader', userDetails: 'userDetails', showCheckoutForm: 'showCheckoutForm' })
  },
  watch: {
    loader: {
      handler () {
        this.showLoader = this.loader
      }
    },
    userDetails: function () {
      if (!this.userDetails || !this.userDetails.locale) return
      if (this.userDetails.locale !== this.$i18n.locale) {
        const localeFromSF = this.userDetails.locale === 'it-IT' ? 'it-IT' : this.userDetails.locale.split('_')[0]
        /*  this.$i18n.locale = localeFromSF */
        // testare qui il cambio lingua
        parent.postMessage({ type: 'changeLocale', id: 'change locale', options: { lang: localeFromSF } }, '*')
      }
    }
  },
  methods: {
    onLocaleChange (e) {
      this.$refs.mainNavigation.setConfigurations()
    },
    handleToggleOverlay (state) {
      if (typeof state === 'undefined') {
        this.showOverlay = !this.showOverlay
        return
      }
      this.showOverlay = state
    },
    ...mapMutations({ setShowCheckoutForm: 'setShowCheckoutForm' })
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: block;
  width: 100%;
  height: 100%;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 91;
  }
}
#CybotCookiebotDialog { border-radius: 0 !important; }
#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText { display: none !important; }
#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider { background-color: #b9b9b9 !important; }
#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before { background-color: #000 !important; }
#CybotCookiebotDialogBody .CybotCookiebotDialogBodyBottomWrapper {
  border: 0 !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
@media (max-width: 768px) {
  #CybotCookiebotDialog {
    transform: translate(0%,0%) !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
  }
  #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper {
    width: auto !important;
    gap: 10px !important;
  }
  #CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * { font-size: 14px !important; }
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper { flex-direction: row !important; }
  #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border-bottom: 0 !important;
    padding: 0 !important;
  }
  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
    justify-content: flex-start !important;
    margin: 0 0 0 1em !important;
  }
}
</style>
