<template>
  <Utility
    icon="Undo"
    :status="status"
    @click.native="undo"
  >
    {{ $t('undo') }}
  </Utility>
</template>

<script>
import Utility from 'ui/src/components/Utilities/Utility'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Utility
  },
  i18n: {
    messages: {
      'it-IT': {
        undo: 'Annulla ultima modifica'
      },
      en: {
        undo: 'Undo the last edit'
      },
      fr: {
        undo: 'Annule la dernière modification'
      },
      es: {
        undo: 'Deshace la última edición'
      }
    }
  },
  data: () => ({
    optionsHistory: [],
    doUndo: false
  }),
  computed: {
    ...mapGetters({ modelOptions: 'modelOptions', modelId: 'modelId', siblings: 'siblings', userDetails: 'userDetails' }),
    status: {
      get () {
        return this.optionsHistory.length > 1
          ? 'active'
          : 'disabled'
      }
    },
    isDealer () {
      return process.env.VUE_APP_ENVIRONMENT === 'dealers'
    }
  },
  watch: {
    modelOptions: {
      handler (value, oldValue) {
        if (this.doUndo) {
          this.doUndo = false
          return
        }

        if (this.optionsHistory.length > 0 && JSON.stringify(value) === JSON.stringify(oldValue)) {
          return
        }

        if (Object.keys((value)) <= 0) return

        this.optionsHistory = this.optionsHistory.concat(value)
      },
      deep: true,
      immediate: true
    },
    modelId: {
      handler () {
        this.optionsHistory = []
      }
    }
  },
  methods: {
    ...mapMutations({ setModelOptions: 'setModelOptions' }),
    undo () {
      if (this.btnStatus === 'disabled') return
      this.doUndo = true

      this.setModelOptions(this.optionsHistory[this.optionsHistory.length - 2])
      this.optionsHistory = this.optionsHistory.slice(0, -1)

      this.$gtag.event('undo_edit', {
        event_category: this.isDealer ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    }
  }
}
</script>
