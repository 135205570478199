import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      appViewerGetter: 'appViewerGetter',
      modelOptions: 'modelOptions',
      modelId: 'modelId',
      isChangingAll: 'isChangingAll',
      editSingle: 'editSingle',
      userDetails: 'userDetails'
    })
  },
  methods: {
    ...mapMutations({ setEditSingle: 'setEditSingle', setLoader: 'setLoader' }),
    ...mapActions({ changeModelState: 'changeModelState' }),
    async changeState (id, value) {
      if (value === this.modelOptions[id]) return
      this.setLoader(true)
      if (!this.isChangingAll) {
        if (this.editSingle) {
          await this.appViewerGetter().getElementSelected().articoloGemo.element.configureVariant(id, [value])
          this.setEditSingle(false)
          const children = this.appViewerGetter().getElementSelected().articoloGemo.articoliGemoChildMap
          await this.appViewerGetter().getElementSelected().articoloGemo.setArticoloGemoSelected(children[0])
          this.$root.$emit('updatePrice')
        } else {
          // QUI GUIDA LE MODIFICHE
          this.changeModelState({ category: id, value: value })
        }
      } else {
        if (id.includes('FAS') || id.includes('FIA')) {
          const keys = Object.keys(this.modelOptions).filter(k => (k.includes('FAS') || k.includes('FIA')))
          this.changeModelState(keys.map(k => ({ category: k, value })))
        }
        if (id.includes('DSED') || id.includes('TSCH')) {
          const keys = Object.keys(this.modelOptions).filter(k => (k.includes('DSED') || k.includes('TSCH')))
          this.changeModelState(keys.map(k => ({ category: k, value })))
        }
        if (id.includes('TCUS')) {
          const keys = Object.keys(this.modelOptions).filter(k => (k.includes('TCUS')))
          this.changeModelState(keys.map(k => ({ category: k, value })))
        }
      }

      this.setLoader(false)

      this.$gtag.event('change_option', {
        event_category: process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    }
  }
}
