<template>
  <Utilities>
    <SocialSharing v-if="showSocials" />
    <Support @toggle-overlay="handleToggleOverlay" />
    <Reset />
    <Undo />
    <Open />
  </Utilities>
</template>

<script>
import Utilities from 'ui/src/components/Utilities'
import Undo from './Undo'
import Open from './Open'
import Reset from './Reset'
import Support from './Support'
import SocialSharing from './SocialSharing'

export default {
  components: {
    Utilities,
    Undo,
    Open,
    Reset,
    Support,
    SocialSharing
  },
  computed: {
    showSocials () {
      return process.env.VUE_APP_ENVIRONMENT !== 'dealers'
    }
  },
  methods: {
    handleToggleOverlay (state) {
      this.$emit('toggle-overlay', state)
    }
  }
}
</script>
