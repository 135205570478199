<template>
  <Utility
    v-if="canAnimate"
    icon="Open"
    @click.native="animateModel"
  >
    {{ $t(animationState) }}
  </Utility>
</template>

<script>
import Utility from 'ui/src/components/Utilities/Utility'

import { mapGetters } from 'vuex'

export default {
  components: {
    Utility
  },
  i18n: {
    messages: {
      'it-IT': {
        open: 'Apri ante',
        close: 'Chiudi ante'
      },
      en: {
        open: 'Open doors',
        close: 'Close doors'
      },
      fr: {
        open: 'Ouvrir les portes',
        close: 'Fermer les portes'
      },
      es: {
        open: 'Abrir puertas',
        close: 'Cerrar puertas'
      }
    }
  },
  data: () => ({
    animationDirection: undefined,
    animating: false,
    direction: 1,
    canAnimate: false
  }),
  computed: {
    ...mapGetters({
      appViewerGetter: 'appViewerGetter',
      appViewerReady: 'appViewerReady',
      dressCount: 'dressCount'
    }),
    animationState: {
      get () {
        if (this.direction === 1) return 'open'
        if (this.direction === -1) return 'close'
        return 'open'
      }
    },
    article: {
      get () {
        if (!this.appViewerReady) return
        const article = this.appViewerGetter().getElementSelected()
        return article
      }
    }
  },
  watch: {
    appViewerReady: {
      handler (ready) {
        if (!ready) return

        this.appViewerGetter().onAnimationReady = () => {
          this.bindListeners()
          this.canAnimate = this.article.metronStructure.animations.length > 0
        }
      }
    },
    dressCount: {
      handler () {
        // console.log(this.article)
        // this.canAnimate = this.article.metronStructure.animations.length > 0
      }
    },
    article: {
      handler (article) {
        if (article) {
          this.canAnimate = article.metronStructure.animations.length > 0
        }
      }
    }
  },
  methods: {
    async animateModel () {
      if (this.animating) return
      const element = this.appViewerGetter().getElementSelected()
      if (element.hasLights()) {
        await element.toggleLightsWithAnimation()
      }
      await element.animate()
    },
    bindListeners () {
      this.appViewerGetter().onBeforeAnimationStart = (articoloGemo, direction) => {
        this.direction = direction
        this.animating = true
      }
      this.appViewerGetter().onAfterAnimationStart = (articoloGemo, direction) => {
        this.direction = direction
      }
      this.appViewerGetter().onBeforeAnimationStop = (articoloGemo, direction) => {
        this.direction = direction
      }
      this.appViewerGetter().onAfterAnimationStop = (articoloGemo, direction) => {
        this.direction = direction
        this.animating = false
      }
    }
  }
}
</script>
