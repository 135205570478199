<template>
  <MainNavigation
    v-ismobile
  >
    <Toolbar
      :customize-selected="isOpen('customize')"
      :product-selected="isOpen('category')"
      :export-selected="isOpen('export')"
      :is-open="menuCards.length > 0"
      :show-fake-ecommerce="true"
      @customize="!isOpen('customize') ? openMenuCard({card: 'customize', close: true}) : closeAll()"
      @choose-product="!isOpen('category') ? openMenuCard({card: isMobile ? ['category'] : ['category', 'subcategory'], close: true}) : closeAll()"
      @export="!isOpen('export') ? openExport() : closeAll()"
      @buy="showFakeEcommerce()"
    >
      <template v-slot:customize-label>
        {{ $t('toolbar.customize') }}
      </template>
      <template v-slot:choose-label>
        {{ $t('toolbar.choose') }}
      </template>
      <template
        v-if="hasPrice"
        v-slot:export-label
      >
        {{ isLogged ? $t('toolbar.save') : $t('toolbar.export') }}
      </template>
      <template
        v-else
        v-slot:export-label
      >
        {{ $t('toolbar.export_project') }}
      </template>
      <template v-slot:fake-ecommerce-label>
        {{ isLogged && isDealer ? $t('toolbar.sendOrder') : $t('toolbar.ecommerce') }}
      </template>
    </Toolbar>
    <Customization
      :transition-type="transitionType || 'SlideRight'"
      @open="openMenuCard('customize')"
    />
    <component :is="transitionType">
      <InspirationsCard
        v-if="isOpen('inspirations') && presets"
      />
    </component>
    <Model :transition-type="transitionType || 'SlideRight'" />
    <component :is="transitionType">
      <ExportCard
        v-if="isOpen('export')"
        @close="close('export')"
      />
    </component>
  </MainNavigation>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import groupBy from 'lodash.groupby'
import MainNavigation from 'ui/src/components/MainNavigation'
import Toolbar from 'ui/src/components/Toolbar'
import Customization from './Customization'
import Model from './Model'
import SlideRight from 'ui/src/components/Transitions/SlideRight'
import SlideUp from 'ui/src/components/Transitions/SlideUp'
import InspirationsCard from './Customization/InspirationsCard'
import ExportCard from './ExportCard'
import checkIfHasPrice from '../../mixins/checkIfHasPrice'

export default {
  components: {
    MainNavigation,
    Toolbar,
    Customization,
    SlideRight,
    SlideUp,
    Model,
    ExportCard,
    InspirationsCard
  },
  directives: {
    ismobile: {
      inserted (el, bind, vnode) {
        const checkIfIsMobile = () => {
          if (window.innerWidth > 768) {
            vnode.context.isMobile = false
          } else {
            vnode.context.isMobile = true
          }
        }

        checkIfIsMobile()

        window.addEventListener('resize', () => {
          checkIfIsMobile()
        })
      }
    }
  },
  mixins: [checkIfHasPrice],
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          customize: 'Personalizza il prodotto',
          choose: 'Cambia prodotto',
          save: 'Salva progetto',
          export: 'Scarica PDF',
          ecommerce: 'Richiedi di acquistare',
          export_project: 'Scarica Progetto',
          sendOrder: 'Invia ordine'
        }
      },
      en: {
        toolbar: {
          customize: 'Customize product',
          choose: 'Change product',
          save: 'Save project',
          export: 'Download project',
          ecommerce: 'Request informations',
          export_project: 'Download project',
          sendOrder: 'Send order'
        }
      },
      fr: {
        toolbar: {
          customize: 'Personnaliser le produit',
          choose: 'Changer de produit',
          export: 'Télécharger le projet',
          save: 'Enregistrer le projet',
          ecommerce: 'Veux-tu acheter?',
          export_project: 'Télécharger le projet',
          sendOrder: 'Envoyer la commande'
        }
      },
      es: {
        toolbar: {
          customize: 'Personalizar el producto',
          choose: 'Cambiar de producto',
          export: 'Descargar proyecto',
          save: 'Guardar el proyecto',
          ecommerce: '¿Quieres comprar?',
          export_project: 'Descargar proyecto',
          sendOrder: 'Enviar pedido'
        }
      }
    }
  },
  data: () => ({
    presets: undefined,
    isMobile: true,
    openFakeEcommerce: false
  }),
  computed: {
    ...mapGetters({
      appViewerGetter: 'appViewerGetter',
      appViewerReady: 'appViewerReady',
      modelOptions: 'modelOptions',
      modelId: 'modelId',
      availableConfigurations: 'availableConfigurations',
      dressCount: 'dressCount',
      selectedProduct: 'selectedProduct',
      userDetails: 'userDetails',
      isMultiLevel: 'isMultiLevel'
    }),
    ...mapGetters('menu', { menuCards: 'menuCardsOpen', isOpen: 'isMenuCardOpen' }),
    transitionType: {
      get () {
        return this.isMobile ? 'SlideUp' : 'SlideRight'
      }
    },
    hasFakeEcommerce () {
      // to fix
      return this.isLogged
    },
    isLogged () {
      // return process.env.VUE_APP_ENVIRONMENT === 'dealers'
      return Boolean(this.userDetails) && this.userDetails.token !== undefined
    },
    isDealer () {
      return this.isLogged && this.userDetails.role.toLowerCase() === 'dealer'
    }
  },
  watch: {
    appViewerReady () {
      this.setConfigurations()
    //   if (!this.modelOptions) {
    //     // const baseOptions = this.availableConfigurations.map(opt => ({ [opt.id]: opt.selected }))
    //     // this.setModelOptions(baseOptions)
    //   }
    },
    selectedProduct: {
      async handler (v) {
        if (!v || !v.category) return
        const currentCategory = await this.$contentful.getEntry(v.category)
        this.setDefaultActiveCategory(currentCategory.fields.tesyId)
      },
      immediate: true
    },
    dressCount: {
      handler () {
        if (!this.appViewerReady) return
        this.setConfigurations()
      },
      immediate: true
    },
    modelId: {
      async handler () {
        if (!this.modelId) return
        const presets = await this.$contentful.getEntries({
          content_type: 'preset',
          include: 10,
          locale: this.$i18n.locale
        })
        this.presets = presets.items.filter((preset) => preset.fields.model.fields.tesyId === this.modelId)
        if (this.appViewerReady) this.setConfigurations()
      },
      immediate: true
    },
    modelOptions: {
      handler () {
        if (!this.appViewerReady) return
        if (!this.modelOptions) return
        this.setConfigurations()
      },
      immediate: true
    }
  },
  mounted () {
    const checkIfIsMobile = () => {
      if (window.innerWidth > 768) {
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    }
    checkIfIsMobile()
    window.addEventListener('resize', () => {
      checkIfIsMobile()
    })
  },
  methods: {
    ...mapMutations({ setAvailableConfigurations: 'setAvailableConfigurations', setModelOptions: 'setModelOptions', setIsMultiLevel: 'setIsMultiLevel', setChildren: 'setChildren' }),
    ...mapActions({ getScreenshot: 'getScreenshot' }),
    ...mapMutations('menu', { openMenuCard: 'openMenuCard', closeMenuCard: 'closeMenuCard', closeAll: 'closeAllMenuCards', setDefaultActiveCategory: 'setDefaultActiveCategory' }),
    openExport () {
      this.openMenuCard({ card: 'export', close: true })
      this.getScreenshot()
    },
    showFakeEcommerce () {
      this.getScreenshot()
      this.$emit('open-fake-ecommerce')
      // this.$gtag.event('configuratore [Richiesta Prezzo]', {
      //   event_category: 'configuratore',
      //   event_label: `model_${this.modelId}`
      // })
      // console.log('old____invioconfiguratore [Richiesta Prezzo]')
    },
    getConfigurationValues (type, cat) {
      const articoliGemoChildMap = this.appViewerGetter().element_selected.articoloGemo.articoliGemoChildMap
      const isMultiLevel = Object.keys(articoliGemoChildMap).length > 1
      if (isMultiLevel) {
        const vals = groupBy(cat.Valori, 'c_valore_non_significativo')
        if (cat.ValoriNonSignificativi.length !== 0) {
          return cat.ValoriNonSignificativi.map((subcat) => ({
            id: subcat.c_valore,
            name: subcat.ds_valore,
            type: 'image',
            parentId: cat.c_categoria,
            values: vals[subcat.c_valore].map((value) => ({
              c_valore: value.c_valore,
              ds_valore: value.ds_valore,
              n_R: value.n_R,
              n_G: value.n_G,
              n_B: value.n_B,
              imageUrl: value.imageUrl
            }))
            // cat.Valori
            //   .filter((c) => c.c_valore_non_significativo === subcat.c_valore)
          }))
        } else {
          return cat.Valori.map((value) => ({
            c_valore: value.c_valore,
            ds_valore: value.ds_valore,
            n_R: value.n_R,
            n_G: value.n_G,
            n_B: value.n_B,
            imageUrl: value.imageUrl
          }))
        }
      //   console.log(cat.ValoriNonSignificativi.length)
      //   const p = cat.Valori.map((subcat) => ({
      //     id: subcat.c_valore,
      //     name: subcat.ds_valore,
      //     type: 'list',
      //     parentId: cat.c_categoria,
      //     values: cat.Valori
      //       .filter((c) => c.c_valore_non_significativo === subcat.c_valore)
      //       .map((value) => ({
      //         c_valore: value.c_valore,
      //         ds_valore: value.ds_valore,
      //         n_R: value.n_R,
      //         n_G: value.n_G,
      //         n_B: value.n_B,
      //         imageUrl: value.imageUrl
      //       }))
      //   }))
      //   console.log(p)
      //   return p
      }
      if (cat.c_categoria === 'FVM' && cat.ValoriNonSignificativi.length === 0) {
        return cat.Valori.map((subcat) => ({
          id: subcat.c_valore,
          name: subcat.ds_valore,
          type: 'image',
          parentId: cat.c_categoria,
          values: cat.Valori
            .filter((c) => c.c_valore_non_significativo === subcat.c_valore)
            .map((value) => ({
              c_valore: value.c_valore,
              ds_valore: value.ds_valore,
              n_R: value.n_R,
              n_G: value.n_G,
              n_B: value.n_B,
              imageUrl: value.imageUrl
            }))
        }))
      }
      if (type === 'list') {
        return cat.ValoriNonSignificativi.map((subcat) => ({
          id: subcat.c_valore,
          name: subcat.ds_valore,
          type: 'image',
          parentId: cat.c_categoria,
          values: cat.Valori
            .filter((c) => c.c_valore_non_significativo === subcat.c_valore)
            .map((value) => ({
              c_valore: value.c_valore,
              ds_valore: value.ds_valore,
              n_R: value.n_R,
              n_G: value.n_G,
              n_B: value.n_B,
              imageUrl: value.imageUrl
            }))
        }))
      }

      return cat.Valori.map((value) => ({
        c_valore: value.c_valore,
        ds_valore: value.ds_valore,
        n_R: value.n_R,
        n_G: value.n_G,
        n_B: value.n_B,
        imageUrl: value.imageUrl
      }))
    },
    setConfigurations () {
      if (!this.appViewerGetter().element_selected) return
      const configurations = this.appViewerGetter().element_selected.articoloGemo.getCategorie()
        .filter((cat) => cat.Valori.length > 1)
        .map((cat) => {
          const normalizeTypes = {
            default: 'image',
            '*': 'image',
            list_group: 'list'
          }
          if (cat.c_categoria === 'STRS') {
            cat.fg_ui_type = 'image'
          }
          let type = Object.keys(normalizeTypes).includes(cat.fg_ui_type)
            ? normalizeTypes[cat.fg_ui_type]
            : cat.fg_ui_type
          const values = this.getConfigurationValues(type, cat)

          const articoliGemoChildMap = this.appViewerGetter().element_selected.articoloGemo.articoliGemoChildMap
          const isMultiLevel = Object.keys(articoliGemoChildMap).length > 1

          if (isMultiLevel) {
            if (cat.ValoriNonSignificativi.length !== 0) {
              type = 'list'
            }
          }
          return {
            name: cat.ds_categoria,
            type,
            values: values.map(v => Object.assign({}, v, { selected: cat.c_valore_scelto })),
            id: cat.c_categoria,
            selected: cat.c_valore_scelto
          }
        })

      // if (Object.keys(articoliGemoChildMap).length > 1) {
      // const articoliGemoChild = Object.values(articoliGemoChildMap).filter(function (articoloGemoChild) {
      //   return articoloGemoChild.elemento.fg_vestizione_modificabile === 1
      // })

      // const allChildren = Object.values(articoliGemoChildMap)
      //   .filter(_ => _.elemento.ListaFigli.length > 0)
      //   .filter(_ => _.elemento.VariantiScelte.length > 0)
      //   .map(_ => ({ name: _.c_articolo, id: _.elemento.n_idx, children: _.elemento.ListaFigli.map(x => ({ id: x.n_idx, name: x.DatiArticolo.c_articolo, parent: _.elemento.n_idx })), gcat: _.elemento.DatiArticolo.ds_articolo }))
      // const children = Object.values(articoliGemoChildMap)
      //   .map(_ => ({ name: _.c_articolo, id: _.elemento.n_idx, e: _.elemento, children: _.elemento.ListaFigli.map(x => ({ id: x.n_idx, parent: _.elemento.n_idx })), gcat: _.elemento.DatiArticolo.ds_articolo }))
      // .map(_ => ({ name: _.c_articolo, id: _.elemento.n_idx, f: _.elemento.ListaFigli.map(p => ({ cat: p })), cats: _.elemento.VariantiScelte.map(p => ({ cat: p.c_categoria })), e: _.elemento }))
      // console.log(allChildren)
      // console.log(children)
      // this.setChildren(allChildren)
      // this.setIsMultiLevel(true)
      // }

      const articoliGemoChildMap = this.appViewerGetter().element_selected.articoloGemo.articoliGemoChildMap
      if (Object.keys(articoliGemoChildMap).length > 1) {
        this.setIsMultiLevel(true)
        // console.log(articoliGemoChildMap)
      } else {
        this.setIsMultiLevel(false)
      }
      this.setAvailableConfigurations(configurations)
    }
  }
}
</script>
