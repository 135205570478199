<template>
  <ProductsCard
    v-images-loaded="loadedImgsHandler"
    :overlap="overlap"
    @close="closeMenuCard(['subcategory', 'product'])"
  >
    <template v-slot:title>
      {{ $t('toolbar.subcategory') }}
    </template>
    <template
      v-if="entries"
      v-show="!loading"
    >
      <SingleProduct
        v-for="entry in entries"
        :key="entry.fields.slug"
        :status="subcategoryStatuses[entry.sys.id]"
        @click.native="openCard(entry.fields.slug)"
      >
        <template v-slot:image>
          <PictureSet
            v-if="entry.fields.cover"
            :src="entry.fields.cover.fields.file.url"
            :alt="entry.fields.cover.fields.file.url"
            :title="entry.fields.cover.fields.file.url"
            :size="imageSize"
          />
        </template>
        <template v-slot:label>
          {{ entry.fields.name }}
        </template>
      </SingleProduct>
    </template>
    <SkeletonTransition>
      <Skeleton v-if="loading" />
    </SkeletonTransition>
  </ProductsCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import imagesLoaded from 'vue-images-loaded'

import ProductsCard from 'ui/src/components/ProductsCard'
import PictureSet from 'ui/src/components/PictureSet'
import SingleProduct from 'ui/src/components/SingleProduct'
import SkeletonTransition from 'ui/src/components/Transitions/Skeleton'
import Skeleton from '../Skeleton'

export default {
  components: { ProductsCard, SingleProduct, PictureSet, Skeleton, SkeletonTransition },
  directives: {
    imagesLoaded
  },
  props: {
    open: {
      type: String,
      default: () => ''
    },
    overlap: {
      type: Boolean,
      default: () => false
    }
  },
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          subcategory: 'Prodotto'
        }
      },
      en: {
        toolbar: {
          subcategory: 'Product'
        }
      },
      fr: {
        toolbar: {
          subcategory: 'Produit'
        }
      },
      es: {
        toolbar: {
          subcategory: 'Producto'
        }
      }
    }
  },
  data: () => ({
    entries: undefined,
    loading: true,
    selectedSubcategory: undefined,
    imageSize: [
      {
        media: 1920,
        attr: {
          w: 720,
          h: 482,
          fit: 'fill'
        }
      },
      {
        media: 768,
        attr: {
          w: 600,
          h: 402,
          fit: 'fill'
        }
      },
      {
        media: 0,
        attr: {
          w: 380,
          h: 254,
          fit: 'fill'
        }
      }
    ]
  }),
  computed: {
    ...mapGetters({ modelId: 'modelId', selectedProduct: 'selectedProduct' }),
    ...mapGetters('menu', { menuCardsOpen: 'menuCardsOpen', activeCards: 'activeCards', defaultActiveCategory: 'defaultActiveCategory' }),
    subcategoryStatuses: {
      get () {
        return this.entries.map((e) => {
          return {
            key: e.sys.id,
            value: e.sys.id === this.selectedSubcategory
              ? 'selected'
              : e.fields.slug === this.activeCards.subcategory
                ? 'current'
                : 'active'
          }
        })
          .reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
      }
    }
  },
  watch: {
    'activeCards.category': {
      async handler (v) {
        const categoryToFetch = v || this.defaultActiveCategory
        const entries = await this.$contentful.getEntries({
          content_type: 'category',
          'fields.tesyId': categoryToFetch,
          include: 3,
          locale: this.$i18n.locale
        })
        this.entries = entries.items[0].fields.subcategories
      },
      immediate: true,
      deep: true
    },
    modelId: {
      handler () {
        if (!this.selectedProduct) return
        this.selectedSubcategory = this.selectedProduct.subcategory
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('menu', { openMenuCard: 'openMenuCard', closeMenuCard: 'closeMenuCard', setActiveCards: 'setActiveCards' }),
    openCard (id) {
      this.openMenuCard('product')
      this.setActiveCards({ subcategory: id })
    },
    loadedImgsHandler (val) {
      console.log(val)
      this.loading = false
    }
  }
}
</script>
