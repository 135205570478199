<template>
  <div>
    <Export
      :pdf-button-status="pdfBtnStatus"
      :loading="loading"
      :is-logged="Boolean(userDetails)"
      @close="closeMenuCard('export')"
      @get-obj="getObj"
      @get-pdf="getPdf"
      @get-pdf-price="getPdfPrice"
      @save-project="saveProject"
    >
      <template v-slot:title>
        {{ $t('export.summary') }}
      </template>
      <template v-slot:image>
        <img
          v-if="screenshot"
          :src="`data:image/png;base64,${screenshot}`"
        >
      </template>
      <template v-slot:price>
        <span v-if="isLoggedIn && !isArchitect && price && price > 0">{{ getCurrency }} {{ priceIncrease ? Math.round(price + price*priceIncrease) : Math.round(price) }}</span>
      </template>
      <template v-slot:details>
        <span v-if="name"><strong>{{ $t('export.product') }}</strong>: {{ name }}</span>
        <span
          v-for="option of optionsSelected"
          :key="option.key"
        >
          <strong>{{ option.key }}</strong>: {{ option.value }}
        </span>
      </template>
      <template v-slot:getObj>
        {{ $t('export.getObj') }}
      </template>
      <template v-slot:getPdf>
        {{ $t('export.getPdf') }}
      </template>
      <template
        v-if="!isLoggedIn && hasPrice"
        v-slot:getPdfPrice
      >
        {{ $t('export.getPdfPrice') }}
      </template>
      <template
        v-if="isLoggedIn"
        v-slot:saveProject
      >
        {{ $t('export.saveProject') }}
      </template>
    </Export>

    <Modal
      ref="modal"
      @submit="showFeedback = true"
      @complete="getPdf(true)"
    />

    <Feedback
      v-if="showFeedback"
      ref="feedback"
      @toggled="toggledFeedback"
      @submit="submitFeedback"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Export from 'ui/src/components/Export'
import Modal from './Modal'
import Feedback from './Feedback'
import checkIfHasPrice from '../../../mixins/checkIfHasPrice'

export default {
  components: { Export, Modal, Feedback },
  mixins: [checkIfHasPrice],
  i18n: {
    messages: {
      'it-IT': {
        export: {
          summary: 'Salva / Esporta',
          product: 'Prodotto',
          getObj: 'Scarica file .obj',
          getPdf: 'Scarica pdf',
          getPdfPrice: 'Scopri il prezzo',
          saveProject: 'Salva Progetto'
        }
      },
      en: {
        export: {
          summary: 'Summary',
          product: 'Product',
          getObj: 'Download .obj file',
          getPdf: 'Download PDF',
          getPdfPrice: 'Show price',
          saveProject: 'Save the project'
        }
      },
      fr: {
        export: {
          summary: 'Récapitulatif',
          product: 'Produit',
          getObj: 'Télécharger le fichier .obj',
          getPdf: 'Télécharger le PDF',
          getPdfPrice: 'Découvrez le prix',
          saveProject: 'Enregistrer le projet'
        }
      },
      es: {
        export: {
          summary: 'Resumen',
          product: 'Producto',
          getObj: 'Descargar archivo .obj',
          getPdf: 'Descargar pdf',
          getPdfPrice: 'Descubrir el precio',
          saveProject: 'Guardar el proyecto'
        }
      }
    }
  },
  data: () => ({
    name: 'LAGO',
    loadPdf: false,
    pdfBtnStatus: 'active',
    showFeedback: false,
    loading: false
  }),
  computed: {
    ...mapGetters({
      appViewerReady: 'appViewerReady',
      appViewerGetter: 'appViewerGetter',
      screenshot: 'screenshot',
      modelOptions: 'modelOptions',
      modelId: 'modelId',
      dataSent: 'dataSent',
      userDetails: 'userDetails'
    }),
    optionsSelected: {
      get () {
        if (!this.modelOptions || !this.appViewerGetter()) return undefined
        return this.appViewerGetter().getElementSelected().articoloGemo.categorie
          .filter((cat) => Object.keys(this.modelOptions).includes(cat.c_categoria))
          .filter((cat) => cat.fg_non_obbligatorio === 0)
          .map((cat) => {
            const value = cat.Valori.find((val) => val.c_valore === cat.c_valore_scelto) // .ds_valore
            return {
              key: cat.ds_categoria,
              value: value ? value.ds_valore : ''
            }
          })
      }
    },
    isLoggedIn () {
      // return process.env.VUE_APP_ENVIRONMENT === 'dealers'
      return Boolean(this.userDetails) && this.userDetails.token !== undefined
    },
    isArchitect () {
      if (!this.isLoggedIn) {
        return false
      } else if (this.userDetails.role && this.userDetails.role.toLowerCase() === 'architect') {
        return true
      } else {
        return false
      }
    },
    price () {
      // to fix
      // if ((!this.appViewerGetter || typeof this.appViewerGetter !== 'function')) return '* * *'
      // if (!this.appViewerGetter().session.logged) return '* * *'
      // return this.appViewerGetter().getElementSelected().getInfo().prices.increasedPrice
      if (Boolean(this.isLoggedIn)) {
        return this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal
        // return this.appViewerGetter().getElementSelected().articoloGemo.elemento.PrezzoListino.n_prezzo_listino
      } else {
        return 0
      }
    },
    getCurrency () {
      if (this.userDetails && this.userDetails.currency) {
        const mapping = {
          Euro: '€',
          Dollaro: '$',
          Pound: '£'
        }
        return mapping[this.userDetails.currency]
      } else {
        return '€'
      }
    },
    priceIncrease () {
      if (this.userDetails && this.userDetails.price_increase) {
        return Number(this.userDetails.price_increase) / 100
      } else {
        return null
      }
    }
  },
  watch: {
    appViewerReady: {
      handler () {
        if (this.appViewerGetter()) {
          this.name = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('menu', { closeMenuCard: 'closeMenuCard' }),
    checkHasPrice () {
      if (this.$i18n.locale !== 'it-IT') return false
      return (this.appViewerGetter().getElementSelected().getInfo().prices && this.appViewerGetter().getElementSelected().getInfo().prices.grossPriceTotal)
        ? this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal > 0
        : false
    },
    getPdfPrice () {
      console.log('getPdfPrice')
      if (process.env.VUE_APP_ENVIRONMENT === 'dealers') {
        this.getPdf(true)
        return
      }
      if (this.isArchitect) {
        console.log('getPdfPrice architect?')
        this.getPdf(false)
        return
      }
      this.toggleModal()
    },
    toggleModal () {
      console.log('TEST a')
      if (!this.dataSent) return this.$refs.modal.toggle()
      this.getPdf(true)
      this.$gtag.event('configuratore [Richiesta Prezzo]', {
        event_category: 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
      window.fbq('trackCustom', 'configuratore [Richiesta Prezzo]', {
        event_category: 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    },
    toggledFeedback (state) {
      if (!state) this.showFeedback = false
    },
    async getObj () {
      try {
        this.loading = true
        const id = `${Date.now()}-${Math.floor(Math.random() * 100000)}`

        this.$gtag.event('configuratore [Download obj]', {
          event_category: process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore',
          event_label: `model_${this.modelId}`,
          event_action: `obj_id_${id}`,
          user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
        })

        await this.appViewerGetter().exportGeometry({
          filename: `LAGO_${this.name}`,
          format: 'OBJ'
        })
        this.showFeedback = true
      } catch (error) {
        console.log('OBJ:', error)
      } finally {
        this.loading = false
      }
    },
    async getPdf (price = false) {
      if (this.isArchitect) {
        price = false
      }
      try {
        this.loading = true
        const id = `${Date.now()}-${Math.floor(Math.random() * 100000)}`

        this.$gtag.event('configuratore [Download pdf]', {
          event_category: process.env.VUE_APP_ENVIRONMENT === 'dealers' ? 'listino_digitale' : 'configuratore',
          event_label: `model_${this.modelId}`,
          event_action: price ? `pdf_id_${id}_with_price` : `pdf_id_${id}`,
          user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
        })
        console.log(this.modelId)

        // request pdf generation to tesy
        if (this.isArchitect) {
          await this.appViewerGetter().createElementPdfDefault(this.appViewerGetter().getElementSelected(), {
            showPrice: false
          })
        } else if (process.env.VUE_APP_ENVIRONMENT !== 'dealers') {
          this.showFeedback = true
          await this.appViewerGetter().createElementPdfDefault(this.appViewerGetter().getElementSelected(), {
            showPrice: price
          })
        } else {
          await this.appViewerGetter().createElementPdfDefault(this.appViewerGetter().getElementSelected(), {
            showPrice: true
          })
        }
      } catch (error) {
        console.error('getPdf:', error)
      } finally {
        this.loading = false
      }
    },
    async submitFeedback (comment) {
      try {
        await fetch(process.env.VUE_APP_FEEDBACK_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            comment,
            modelId: this.modelId,
            modelOptions: this.modelOptions,
            locale: this.$i18n.locale,
            url: window.location.href
          })
        })
      } catch (e) {
        // TODO: error
      }

      this.showFeedback = false
    },
    saveProject () {
      const title = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
      const description = this.appViewerGetter().getElementSelected().articoloGemo.categorie
        .filter((cat) => Object.keys(this.modelOptions).includes(cat.c_categoria))
        .filter((cat) => cat.fg_non_obbligatorio === 0)
        .map((cat) => {
          const value = cat.Valori.find((val) => val.c_valore === cat.c_valore_scelto) // .ds_valore
          return {
            key: cat.ds_categoria,
            value: value ? value.ds_valore : ''
          }
        }).map(detail => `${detail.key}: ${detail.value}`)
        .join('. ')
      const details = {}
      details.code = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.c_articolo
      details.width = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.n_dim_1.toString()
      details.height = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.n_dim_3.toString()
      details.depth = this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.n_dim_2.toString()
      const price = this.appViewerGetter().getElementSelected().articoloGemo.elemento.prices.grossPriceTotal ? this.appViewerGetter().getElementSelected().articoloGemo.elemento.prices.grossPriceTotal.toString() : '0'
      parent.postMessage({ type: 'saveConfiguration', id: 'save configuration', options: { url: this.$route.fullPath, title: title, description: description, details: details, price: price } }, '*')
    }
  }
}
</script>
