<template>
  <div class="anagrafica-clienti-ecommerce">
    <ValidationObserver
      v-if="!sent && !sending && !sendError"
      ref="anagraficaClienti"
      slim
      tag="form"
      class="anagrafica-clienti-ecommerce__form"
      @submit.prevent="submitLead"
    >
        <ValidationProvider
          class="ordine-interno flex flex-col"
        >
          <SimpleInput
            v-model="internalOrder"
            :placeholder="$t('form.reference')"
            name="Ordine"
            :untouched="internalOrder.length === 0"
            :valid="internalOrder.length !== 0"
          />
        </ValidationProvider>
        <div class="anagrafica-clienti-ecommerce__title">
          {{ $t('title') }}
        </div>
        <div class="anagrafica-clienti-ecommerce__sub-title">
          {{ $t('form.compulsory') }}
        </div>
        <div class="anagrafica-clienti-ecommerce__form__cols">
          <div>
            <ValidationProvider
              v-slot="{ errors, valid }"
              rules="required"
              class="flex flex-col"
            >
              <SimpleInput
                v-model="name"
                :placeholder="$t('form.name')"
                name="Nome"
                :error-message="errors.length > 0 ? errors[0] : ''"
                :valid="valid"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, untouched, valid }"
              rules="required|email"
              class="flex flex-col"
            >
              <SimpleInput
                v-model="email"
                placeholder="E-mail*"
                name="Email"
                :error-message="errors.length > 0 ? errors[0] : ''"
                :untouched="untouched"
                :valid="valid"
              />
            </ValidationProvider>
            <SimpleInput
              v-model="address"
              :placeholder="$t('form.address')"
              name="indirizzo"
              :untouched="address.length === 0"
              :valid="address.length !== 0"
            />
          </div>
          <div>
            <ValidationProvider
              v-slot="{ errors, untouched, valid }"
              rules="required"
              class="flex flex-col"
            >
              <SimpleInput
                v-model="surname"
                :placeholder="$t('form.surname')"
                name="Cognome"
                :error-message="errors.length > 0 ? errors[0] : ''"
                :valid="valid"
                :untouched="untouched"
              />
            </ValidationProvider>
            <SimpleInput
              v-model="telephone"
              :placeholder="$t('form.telephone')"
              name="telefono"
              :untouched="telephone.length === 0"
              :valid="telephone.length !== 0"
            />
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              mode="eager"
              tag="div"
              class="input-wrapper autocomplete small"
            >
              <SimpleInput
                v-model="city"
                ref="cityInput"
                :placeholder="$t('form.city')"
                name="city"
                :untouched="city.length === 0"
                :valid="city.length !== 0"
                :error-message="errors.length > 0 ? errors[0] : ''"
                @update="e => inputHandler(e)"
                @lost-focus="checkOrClear"
              />
              <div
                v-show="!forceCityClose"
                class="cities"
              >
                <div
                  v-for="(city, i) of cities"
                  :key="i"
                  @click="saveCity(city)"
                >
                {{ city.place_name }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div>
            <MessageInput
              v-model="note"
              :placeholder="$t('form.notes')"
              name="note"/>
          </div>
        </div>
        <div class="anagrafica-clienti-ecommerce__form__bottom">
          <div class="flex flex-col">
            <div class="privacy-text" v-html="$t('form.privacyIntro')"></div>
            <input
              id="privacy"
              v-model="privacy"
              class="form-input"
              type="checkbox"
              name="privacy"
              :placeholder="'Accetto i termini'"
            >
            <label
              for="privacy"
              class="form-label privacy"
            >
              <span class="fake-checkbox">
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21 16"
                  class="w-full"
                >
                  <path
                    d="M2 6.19 8.5 13 19 2"
                    stroke="currentColor"
                    stroke-width="4"
                  />
                </svg>
              </span>
              <span>{{ $t('form.privacy') }}</span>
            </label>
            <ValidationProvider
              class="flex flex-row flex-wrap"
            >
              <input
                id="marketing"
                v-model="marketing"
                class="form-input"
                type="checkbox"
                name="marketing"
                :placeholder="'Accetto i termini'"
              >
              <label
                for="marketing"
                class="form-label privacy marketing"
              >
                <span class="fake-checkbox">
                  <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21 16"
                    class="w-full"
                  >
                    <path
                      d="M2 6.19 8.5 13 19 2"
                      stroke="currentColor"
                      stroke-width="4"
                    />
                  </svg>
                </span>
                <span>{{ $t('form.marketing') }}</span>
              </label>
            </ValidationProvider>
          </div>
          <RoundButton
            type="submit"
            mode="dark"
          >
            {{ $t("form.send") }}
          </RoundButton>
        </div>
        <div v-if="!isFormValid" class="anagrafica-clienti-ecommerce__sub-title error">{{ $t('form.compulsoryError') }}</div>
    </ValidationObserver>
    <Loader
      v-else-if="!sent && sending"
      :is-absolute="true"
    />
    <div v-else-if="!sent && !sending && sendError">
      <BigTitle> {{ $t('form.error') }} </BigTitle>
    </div>
    <div
      v-else
      class="anagrafica-clienti-ecommerce__sent"
    >
      <BigTitle> {{ $t('form.sent') }} </BigTitle>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SimpleInput from '~/../ui/src/components/SimpleInput'
import MessageInput from '~/../ui/src/components/MessageInput'
import RoundButton from '~/../ui/src/components/Buttons/RoundButton'
import BigTitle from '~/../ui/src/components/Typography/BigTitle'
import Loader from '~/../ui/src/components/Loader'
import axios from 'axios'
import mboxGeo from '@mapbox/mapbox-sdk/services/geocoding'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
extend('email', {
  ...email,
  message: "Il campo deve essere un'indirizzo email valido"
})

extend('required', {
  ...required,
  message: 'Questo campo è obbligatorio'
})

export default {
  components: {
    BigTitle,
    Loader,
    RoundButton,
    SimpleInput,
    MessageInput,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    optionsSelected: {
      type: Array,
      default: null
    },
    measures: {
      type: Array,
      default: null
    },
    price: {
      type: Number,
      default: null
    },
    isDealer: {
      type: Boolean,
      default: true
    },
    articleQty: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      email: '',
      name: '',
      surname: '',
      address: '',
      telephone: '',
      city: '',
      note: '',
      internalOrder: '',
      missingInternal: false,
      privacy: false,
      marketing: false,
      sendError: false,
      sent: false,
      sending: false,
      isFormValid: true,
      cities: [],
      forceCityClose: true,
      savedCity: undefined,
      mapBoxGeo: undefined
    }
  },
  mounted () {
    this.mapBoxGeo = mboxGeo({ accessToken: 'pk.eyJ1IjoibGFnb2Rlc2lnbiIsImEiOiJjanIxc3hpaDcwcmVtM3hydTZveTZrcTBiIn0.VnmaRvgxt_R5tHG-n8ObVg' })
  },
  i18n: {
    messages: {
      'it-IT': {
        title: 'Anagrafica cliente',
        form: {
          name: 'Nome*',
          surname: 'Cognome*',
          address: 'Indirizzo',
          telephone: 'Telefono',
          city: 'Città*',
          reference: 'Riferimento ordine interno*',
          referenceRequired: 'Campo richiesto',
          send: 'Invia richiesta',
          sent: 'Ordine inviato.',
          error: 'Ci scusiamo, ma c\'è stato un errore. Riprova più tardi.',
          privacyIntro: 'Letta e compresa <a href="https://www.lago.it/privacy/">l\'informativa della privacy del sito </a>e il suo paragrafo 3',
          privacy: 'Acconsento al trattamento dei miei dati personali per la finalità di Profilazione.',
          marketing: 'Acconsento al trattamento dei miei dati personali per la finalità di Marketing.',
          notes: 'Note ordine',
          compulsory: 'I campi contrasegnati con (*) sono obbligatori',
          compulsoryError: 'Verifica di aver inserito tutti i campi obbligatori'
        }
      },
      en: {
        title: 'Customer data',
        form: {
          reference: 'Internal order reference*',
          referenceRequired: 'Required field',
          name: 'Name*',
          surname: 'Surname*',
          address: 'Address',
          telephone: 'Telephone',
          city: 'City*',
          send: 'Send request',
          sent: 'Order sent.',
          error: 'Sorry, but there was an error. Try later.',
          privacyIntro: 'Read and understood <a href="https://www.lago.it/en/privacy/">the site\'s Privacy Policy</a> and its Paragraph 3.',
          privacy: 'I consent to the processing of my personal data for the purpose of Profiling .',
          marketing: 'I agree to the processing of my personal data for Marketing purposes ',
          notes: 'Order notes',
          compulsory: 'Fields marked with (*) are required',
          compulsoryError: 'Check that you have entered all the mandatory fields'
        }
      },
      es: {
        title: 'Datos de los clientes',
        form: {
          reference: 'Referencia de orden interna*',
          referenceRequired: 'Required field',
          name: 'Nombre*',
          surname: 'Apellido*',
          address: 'Direcciòn',
          telephone: 'Teléfono',
          city: 'Ciudad*',
          send: 'Enviar la solicitud',
          sent: 'Pedido enviado',
          error: 'Lo siento, pero hubo un error. Intenta más tarde.',
          privacy: 'Lee y acepta los términos y condiciones del servicio',
          marketing: 'Acconsento al trattamento dei miei dati personali per la finalità di Marketing.',
          notes: 'Notas del pedido',
          compulsory: 'Los campos marcados con (*) son obligatorios',
          compulsoryError: 'Comprueba que has introducido todos los campos obligatorios'
        }
      },
      fr: {
        title: 'Données client',
        form: {
          reference: 'Référence de commande interne*',
          referenceRequired: 'Required field',
          name: 'Prénom*',
          surname: 'Nom*',
          address: 'Adresse',
          telephone: 'Téléphone',
          city: 'Ville*',
          send: 'Envoyer la demande',
          sent: 'Commande envoyée.',
          error: 'Désolé, mais une erreur s\'est produite. Essayer plus tard.',
          privacy: 'Lire et accepter les termes et conditions de service',
          marketing: 'Acconsento al trattamento dei miei dati personali per la finalità di Marketing.',
          notes: 'Notes de commande',
          compulsory: 'Les champs marqués d\'un (*) sont obligatoires',
          compulsoryError: 'Vérifiez que vous avez rempli tous les champs obligatoires'
        }
      }
    }
  },
  computed: {
    details () {
      return this.optionsSelected && this.optionsSelected.length ? this.optionsSelected.map(detail => `<strong>${detail.key}</strong>: ${detail.value}`).join('<br/>') : null
    },
    getCurrency () {
      if (this.userDetails && this.userDetails.currency) {
        const mapping = {
          Euro: '€',
          Dollaro: '$',
          Pound: '£'
        }
        return mapping[this.userDetails.currency]
      } else {
        return '€'
      }
    },
    country: {
      get () {
        if (!this.savedCity || !this.savedCity.context) return null
        const countryContext = this.savedCity.context.find(ctx => ctx.id.includes('country'))
        return countryContext.short_code.toUpperCase()
      }
    },
    region: {
      get () {
        if (!this.savedCity || !this.savedCity.context) return null
        const regionContext = this.savedCity.context.find(ctx => ctx.id.includes('region'))
        return (regionContext.short_code ? regionContext.short_code.split('-')[1] : regionContext.text).toUpperCase()
      }
    },
    /*
    priceIncrease () {
      if (this.userDetails && this.userDetails.price_increase) {
        return Number(this.userDetails.price_increase) / 100
      } else {
        return 1
      }
    }, */
    dealerName () {
      return this.userDetails && this.userDetails.account_name ? this.userDetails.account_name : ''
    },
    dealerEmail () {
      return this.userDetails ? this.userDetails.email : ''
    },
    articleCode () {
      return this.appViewerGetter() ? this.appViewerGetter().getElementSelected().articoloGemo.c_articolo : ''
    },
    ...mapGetters({
      appViewerGetter: 'appViewerGetter',
      screenshot: 'screenshot',
      modelId: 'modelId',
      modelOptions: 'modelOptions',
      url: 'url',
      userDetails: 'userDetails'
    })
  },
  methods: {
    scroll () {
      if (this.$refs.cityInput) {
        window.scrollIntoView(this.$refs.cityInput)
      }
    },
    inputHandler (val) {
      this.city = val
      if (val.length <= 2) {
        this.forceCityClose = true
      }
      if (this.savedCity?.text === val) return
      this.checkGeo()
    },
    checkOrClear () {
      window.setTimeout(() => {
        this.forceCityClose = true
        if (this.savedCity && this.city === this.savedCity.text) return
        this.city = ''
      }, 250)
    },
    saveCity (city) {
      this.forceCityClose = true
      this.city = city.text
      this.savedCity = city
    },
    async checkGeo () {
      if (this.city.length < 2) return
      this.forceCityClose = false

      const res = await this.mapBoxGeo.forwardGeocode({
        query: this.city,
        language: [this.$i18n.locale.split('-')[0]],
        // ...(this.$i18n.locale.split('-')[1] ? { countries: [this.$i18n.locale.split('-')[1]] } : {}),
        types: ['place']
      })
        .send()

      this.cities = res.body.features
    },
    async submitLead () {
      this.sending = true
      this.sent = false
      this.sendError = false
      this.missingInternal = false
      const isValid = await this.$refs.anagraficaClienti.validate()
      if (!isValid) {
        this.sending = false
        this.missingInternal = true
        this.isFormValid = false
        return
      } else {
        this.isFormValid = true
      }

      const res = await axios.post('https://embed.configuratore.lago.it/.netlify/functions/form',
        {
          form: 'request-form',
          email: this.email,
          name: this.name,
          dealerName: this.dealerName,
          dealerEmail: this.dealerEmail,
          surname: this.surname,
          address: this.address,
          telephone: this.telephone,
          city: this.city,
          privacy: this.privacy,
          productCode: this.articleCode,
          internalOrder: this.internalOrder,
          details: this.details,
          quantity: this.articleQty,
          price: this.price * this.articleQty || 0,
          url: this.url,
          currency: this.getCurrency,
          note: this.note
        },
        {
          header: { 'Content-Type': 'application/json' }
        }
      )
      if (res && res.status === 200) {
        this.sent = true
        this.sending = false

        this.$gtag.event('configuratore [Richiesta Acquisto]', {
          event_category: 'configuratore',
          event_label: `model_${this.articleCode}`,
          user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
        })
        window.fbq('trackCustom', 'configuratore [Richiesta Acquisto]', {
          event_category: 'configuratore',
          event_label: `model_${this.articleCode}`,
          user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
        })
      } else {
        this.sent = false
        this.sending = false
        this.sendError = true
      }
    },
    ...mapActions({ sendForm: 'sendForm' })
  }
}
</script>
<style lang="scss">

.anagrafica-clienti-ecommerce {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 14px;
  box-sizing: border-box;
  @media all and (min-width: 768px) {
    padding: 0 4rem;
  }
  .ordine-interno {
    width: 100%;
  }
  &__title {
    font-weight: 500;
    font-size: 30px;
    margin-top: 20px;
    @media all and (min-width: 768px) {
      font-size: 40px;
    }
    @media all and (min-width: 1600px) {
      margin-top: 50px;
    }
  }
  &__sub-title {
    font-weight: 300;
    font-size: 20px;
    margin-top: 10px;
    align-self: flex-start;
    @media all and (min-width: 768px) {
      font-size: 20px;
    }
    @media all and (min-width: 1600px) {
      margin-top: 25px;
    }
    &.error {
      color: #D73030;
    }
  }
  &__sent {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &__cols {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 20px 0;
      @media all and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4rem;
      }
      @media all and (min-width: 1600px) {
        margin: 50px;
      }
    }
    &__bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      @media all and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .privacy-text {
    color: #909090;
    a {
      color: black;
    }
  }
  .form-label {
    margin-bottom: 4px;

    &.privacy {
      display: flex;
      align-items: center;

      a {
        text-decoration: underline;
      }

      .fake-checkbox {
        width: 15px;
        height: 15px;
        border: 1px solid black;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        position: relative;
        background-color: transparent;
        flex-shrink: 0;
        cursor: pointer;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 9px;
          transform: translate(-50%, -50%) scale(0.5);
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .form-input {
    &[type='checkbox'] {
      opacity: 0;
      position: absolute;
    }

    &[type='checkbox']:checked {
      ~label .fake-checkbox svg {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
  }
  .error {
    color: #D73030;;
  }
}

.autocomplete {
  position: relative;
}

.cities {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
  background: white;
  text-align: left;
  line-height: 160%;
  font-size: 1.2rem;
  border: 1px solid #D2D2D2;
  box-sizing: border-box;
  border-top: none;

  div {
    padding: 0.75rem 0.5rem;

    &:hover {
      background: #D2D2D2;
      cursor: pointer;
    }
  }
}

</style>
