import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ appViewerGetter: 'appViewerGetter' }),
    hasPrice: {
      get () {
        if (!this.appViewerGetter || typeof this.appViewerGetter !== 'function') return false
        if (this.$i18n.locale !== 'it-IT') return false
        return (this.appViewerGetter().getElementSelected().getInfo().prices && this.appViewerGetter().getElementSelected().getInfo().prices.grossPriceTotal)
          ? this.appViewerGetter().getElementSelected().getInfo().prices?.grossPriceTotal > 0
          : false
        // return this.appViewerGetter().getElementSelected().articoloGemo.elemento?.PrezzoListino?.n_prezzo_listino > 0
      }
    }
  }
}
