<template>
  <video
    v-if="kon"
    :src="require('./test.mp4')"
    autoplay
    loop
  />
</template>

<script>
import KonamiCode from 'konami-code-js'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    kon: false
  }),
  computed: {
    ...mapGetters({ appViewerGetter: 'appViewerGetter' })
  },
  mounted () {
    // eslint-disable-next-line
    new KonamiCode(() => {
      window.setInterval(this.test, 250)
      const audio = new window.Audio(require('./test.mp3'))
      audio.play()
      this.kon = true
    })
  },
  methods: {
    test () {
      this.appViewerGetter().engine3d.scene.clearColor = new window.BABYLON.Color3(
        Math.random().toFixed(1), Math.random().toFixed(1), Math.random().toFixed(1)
      )
    }
  }
}
</script>

<style scoped>
video {
  position: fixed;
  width: 50vw;
  height: 50vw;
  mix-blend-mode: multiply;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 9999999;
}
</style>
