import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { i18n } from './plugins/i18n'
import { contentfulPlugin } from './plugins/contentful'
import './plugins/tracing'

Vue.use(contentfulPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
