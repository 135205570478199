<template>
  <Modal
    v-if="form"
    ref="modal"
    :button-status="buttonStatus"
    @submit="submit"
  >
    <template v-slot:title>
      {{ $t('title') }}
    </template>
    <template v-slot:description>
      <ValidationObserver
        ref="validator"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(submit)">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            tag="div"
          >
            <input
              v-model="form.name"
              type="text"
              :class="{ error: errors.length > 0 }"
              :placeholder="$t('namePlaceholder')"
            >
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            tag="div"
          >
            <input
              v-model="form.surname"
              type="text"
              :class="{ error: errors.length > 0 }"
              :placeholder="$t('surnamePlaceholder')"
            >
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            tag="div"
            class="autocomplete"
          >
            <input
              ref="cityInput"
              :value="form.city"
              type="text"
              :class="{ error: errors.length > 0 || (savedCity ? form.city !== savedCity.text : false)}"
              :placeholder="$t('cityPlaceholder')"
              @input="e => form.city = e.target.value"
              @focus="scroll"
              @blur="checkOrClear"
            >
            <div
              v-show="!forceCityClose"
              class="cities"
            >
              <div
                v-for="(city, i) of cities"
                :key="i"
                @click="saveCity(city)"
              >
                {{ city.place_name }}
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|email"
            tag="div"
          >
            <input
              v-model="form.email"
              type="text"
              :class="{ error: errors.length > 0 }"
              :placeholder="$t('emailPlaceholder')"
            >
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            tag="div"
          >
            <select
              v-model="form.job"
              :class="{ error: errors.length > 0 }"
            >
              <option
                disabled
                selected
                value=""
              >
                {{ $t('jobPlaceholder') }}
              </option>
              <option
                v-for="(job, i) of $t('jobs')"
                :key="i"
                :value="job.value"
              >
                {{ job.label }}
              </option>
            </select>
          </ValidationProvider>
          <div class="small-text">
            <div
              class="privacy"
              v-html="$t('readPrivacy')"
            />
            <ValidationProvider
              tag="div"
              class="checkbox"
            >
              <input
                id="marketing"
                v-model="form.marketing"
                type="checkbox"
                name="marketing"
              >
              <div
                class="fakebox"
                :class="{ selected: form.marketing }"
                @click="form.marketing = !form.marketing"
              />
              <label for="marketing">{{ $t('marketing') }}</label>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="checkbox"
            >
              <input
                id="profiling"
                v-model="form.profiling"
                type="checkbox"
                name="profiling"
              >
              <div
                class="fakebox"
                :class="{ selected: form.profiling }"
                @click="form.profiling = !form.profiling"
              />
              <label for="profiling">{{ $t('profiling') }}</label>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template v-slot:button>
      {{ $t('discoverPrice') }}
    </template>
    <template
      v-if="error"
      v-slot:error
    >
      {{ $t('notValid') }}
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Modal from 'ui/src/components/Modal'

import { required, email } from 'vee-validate/dist/rules'
import mapBoxCitySelection from '../../../../mixins/mapBoxCitySelection'

// Add the required rule
extend('required', {
  ...required
})

// Add the email rule
extend('email', {
  ...email
})

export default {
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [mapBoxCitySelection],
  i18n: {
    messages: {
      'it-IT': {
        title: 'Inserisci i tuoi dati',
        namePlaceholder: 'Inserisci il tuo nome',
        surnamePlaceholder: 'Inserisci il tuo cognome',
        cityPlaceholder: 'Inserisci la tua città',
        emailPlaceholder: 'Inserisci la tua email',
        jobPlaceholder: 'Inserisci la tua professione',
        newsletter: 'Richiedo l’invio della vostra Newsletter',
        marketing: 'Acconsento al trattamento dei miei dati personali per la finalità di Marketing',
        profiling: 'Acconsento al trattamento dei miei dati personali per la finalità di Profilazione.',
        jobs: [
          {
            value: 'Imprenditore',
            label: 'Imprenditore'
          },
          {
            value: 'Manager',
            label: 'Manager'
          },
          {
            value: 'Architetto',
            label: 'Architetto'
          },
          {
            value: 'Interior Designer',
            label: 'Interior Designer'
          },
          {
            value: 'Privato',
            label: 'Privato'
          },
          {
            value: 'Rivenditore',
            label: 'Rivenditore'
          }
        ],
        readPrivacy: 'Letta e compresa l’<a href="https://www.lago.it/privacy/" style="color:blue">Informativa Privacy</a> e il suo Paragrafo 3.',
        discoverPrice: 'Scopri il prezzo',
        notValid: 'Completa tutti i campi per continuare'
      },
      en: {
        title: 'Enter your data',
        namePlaceholder: 'Enter your first name',
        surnamePlaceholder: 'Enter your surname',
        cityPlaceholder: 'Enter your city',
        emailPlaceholder: 'Enter your e-mail',
        jobPlaceholder: 'Enter your role',
        newsletter: 'Please send me your newsletter',
        marketing: 'I consent to my personal data being processed for marketing purposes.',
        profiling: 'I consent to my personal data being processed for profiling purposes.',
        jobs: [
          {
            value: 'Imprenditore',
            label: 'Business owner'
          },
          {
            value: 'Manager',
            label: 'Manager'
          },
          {
            value: 'Architetto',
            label: 'Architect'
          },
          {
            value: 'Interior Designer',
            label: 'Interior designer'
          },
          {
            value: 'Privato',
            label: 'General public'
          },
          {
            value: 'Rivenditore',
            label: 'Retailer'
          }
        ],
        readPrivacy: 'I have read and understood the <a href="https://www.lago.it/privacy/" style="color:blue">Privacy Policy</a>, in particular, paragraph 3.',
        discoverPrice: 'Show price',
        notValid: 'Please complete all fields to continue'
      },
      fr: {
        title: 'Saisissez vos données',
        namePlaceholder: 'Saisissez votre prénom',
        surnamePlaceholder: 'Saisissez votre nom',
        cityPlaceholder: 'Saisissez votre ville',
        emailPlaceholder: 'Saisissez votre e-mail',
        jobPlaceholder: 'Saisissez votre profession',
        newsletter: 'Je demande l’envoi de votre Newsletter',
        marketing: 'J’accepte le traitement de mes données personnelles à des fins de marketing',
        profiling: 'J’accepte le traitement de mes données personnelles à des fins de profilage.',
        jobs: [
          {
            value: 'Imprenditore',
            label: 'Entrepreneur'
          },
          {
            value: 'Manager',
            label: 'Manager'
          },
          {
            value: 'Architetto',
            label: 'Architecte'
          },
          {
            value: 'Interior Designer',
            label: 'Décorateur d’intérieurs'
          },
          {
            value: 'Privato',
            label: 'Particulier'
          },
          {
            value: 'Rivenditore',
            label: 'Revendeur'
          }
        ],
        readPrivacy: 'J’ai lu et compris l’<a href="https://www.lago.it/privacy/" style="color:blue">avis de confidentialité</a> et son Paragraphe 3.',
        discoverPrice: 'Découvrez le prix',
        notValid: 'Complétez tous les champs pour continuer'
      },
      es: {
        title: 'Introduce tus datos',
        namePlaceholder: 'Introduce tu nombre',
        surnamePlaceholder: 'Introduce tu apellido',
        cityPlaceholder: 'Introduce tu ciudad',
        emailPlaceholder: 'Introduce tu email',
        jobPlaceholder: 'Introduce tu profesión',
        newsletter: 'Solicito el envío de vuestra Newsletter',
        marketing: 'Doy mi consentimiento para el tratamiento de mis datos personales para la finalidad de Marketing',
        profiling: 'Doy mi consentimiento para el tratamiento de mis datos personales para la finalidad de Personalización.',
        jobs: [
          {
            value: 'Imprenditore',
            label: 'Empresario'
          },
          {
            value: 'Manager',
            label: 'Manager'
          },
          {
            value: 'Architetto',
            label: 'Arquitecto'
          },
          {
            value: 'Interior Designer',
            label: 'Diseñador de interiores'
          },
          {
            value: 'Privato',
            label: 'Privado'
          },
          {
            value: 'Rivenditore',
            label: 'Minorista'
          }
        ],
        readPrivacy: 'He leído y comprendido la <a href="https://www.lago.it/privacy/" style="color:blue">Política de Privacidad</a> y su Párrafo 3.',
        discoverPrice: 'Descubrir el precio',
        notValid: 'Completa todos los campos para continuar'
      }
    }
  },
  data: () => ({
    form: {
      name: '',
      surname: '',
      email: '',
      city: '',
      job: '',
      marketing: false,
      profiling: false
    },
    buttonStatus: 'active',
    error: false
  }),
  computed: {
    optionsSelected: {
      get () {
        if (!this.modelOptions || !this.appViewerGetter()) return undefined
        return [{
          key: 'PRODOTTO',
          value: this.appViewerGetter().getElementSelected().articoloGemo.elemento.DatiArticolo.ds_articolo
        }].concat(
          this.appViewerGetter().getElementSelected().articoloGemo.categorie
            .filter((cat) => Object.keys(this.modelOptions).includes(cat.c_categoria))
            .filter((cat) => cat.fg_non_obbligatorio === 0)
            .map((cat) => {
              const value = cat.Valori.find((val) => val.c_valore === cat.c_valore_scelto) // .ds_valore
              return {
                key: cat.ds_categoria,
                value: value ? value.ds_valore : ''
              }
            })
        )
      }
    },
    details: {
      get () {
        return this.optionsSelected
          .map(detail => `${detail.key}: ${detail.value}`)
          .join('. ')
      }
    },
    ...mapGetters({ appViewerGetter: 'appViewerGetter', modelId: 'modelId', modelOptions: 'modelOptions', userDetails: 'userDetails' })
  },
  methods: {
    ...mapMutations({
      setDataSent: 'setDataSent'
    }),
    scroll () {
      if (this.$refs.cityInput) {
        window.scrollIntoView(this.$refs.cityInput)
      }
    },
    toggle () {
      this.$refs.modal.toggle()
    },
    async submit () {
      this.error = !(await this.$refs.validator.validate({ silent: false })) || !this.savedCity
      if (this.error) return

      this.buttonStatus = 'loading'
      const regionContext = this.savedCity.context.find(ctx => ctx.id.includes('region'))
      const region = (regionContext.short_code ? regionContext.short_code.split('-')[1] : regionContext.text).toUpperCase()

      const countryContext = this.savedCity.context.find(ctx => ctx.id.includes('country'))
      const country = countryContext.short_code.toUpperCase()

      const city = this.savedCity.text

      try {
        await fetch('https://configurator.lago.it/.netlify/functions/configurator', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.form.name,
            surname: this.form.surname,
            email: this.form.email,
            city,
            region,
            country,
            job: this.form.job,
            marketing: this.form.marketing,
            profiling: this.form.profiling,
            locale: this.$i18n.locale,
            fullPath: this.$route.fullPath,
            details: this.details
          })
        })
      } catch (error) {
        // TODO: error
        console.log(error)
      }

      try {
        await fetch('https://europe-west1-lago-master.cloudfunctions.net/leads-configurator', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.form.name,
            surname: this.form.surname,
            email: this.form.email,
            city,
            region,
            country,
            job: this.form.job,
            newsletter: this.form.newsletter,
            marketing: this.form.marketing,
            profiling: this.form.profiling,
            locale: this.$i18n.locale,
            tfa_80: this.$route.fullPath,
            tfa_81: this.details
          })
        })
      } catch (error) {
        console.log(error)
      }

      this.setDataSent()
      this.toggle()
      this.buttonStatus = 'active'
      this.$emit('submit')
      this.$emit('complete')

      this.$gtag.event('configuratore [Richiesta Prezzo]', {
        event_category: 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })

      window.fbq('trackCustom', 'configuratore [Richiesta Prezzo]', {
        event_category: 'configuratore',
        event_label: `model_${this.modelId}`,
        user_logged: this.userDetails !== null ? `logged_${this.userDetails.role.toLowerCase()}` : 'not_logged'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~ui/src/assets/styles/typography' as t;
@use '~ui/src/assets/styles/easing' as e;
@use '~ui/src/assets/styles/colors' as c;

input,
select {
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid c.$mid-light-gray;
  padding: 0.75rem 0.5rem;
  box-sizing: border-box;
  position: relative;
  display: block;
  border-radius: 0;
  margin: 2rem 0;
  outline: none;
  box-shadow: none;
  position: relative;
  transition: border-color 0.15s e.$in, border-width 0.15s e.$in;

  &:focus {
    border-color: #000;
  }

  &.error {
    background-color: #fdd;
  }
}

form {
  padding-bottom: 2rem;
}

.checkbox {
  display: grid;
  grid-template-columns: 0.75rem 1fr;
  text-align: left;
  align-items: center;
  grid-column-gap: 1rem;
  cursor: pointer;
  margin-bottom: 0.7rem;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.7;
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }

  .fakebox {
    width: 0.75rem;
    height: 0.75rem;
    border: 1px solid c.$mid-light-gray;
    position: relative;
    &::after {
      content: '';
      background: black;
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.4);
      opacity: 0;
      transition: opacity 0.2s ease, transform 0.2s ease;
    }
    &.selected {
      &::after {
        transform: scale(1) translate3d(-50%, -50%, 0);
        opacity: 1;
      }
    }
  }
}

.privacy {
  margin-bottom: 1rem;
  text-align: left;
}

.autocomplete {
  position: relative;
}

.cities {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
  background: white;
  text-align: left;
  line-height: 160%;
  font-size: 1.2rem;
  border: 1px solid #D2D2D2;
  box-sizing: border-box;
  border-top: none;

  div {
    padding: 0.75rem 0.5rem;

    &:hover {
      background: #D2D2D2;
      cursor: pointer;
    }
  }
}

.small-text {
  font-size: 0.75rem;
}
</style>
