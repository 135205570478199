<template>
  <ImagesCard
    v-if="category.values"
    @close="$emit('close')"
  >
    <template v-slot:title>
      <div>{{ category.name }}</div>
      <Customization v-if="editAll && isVisible">
        <template v-slot:label>
          <SwitcherC
            :selected="selected"
            @toggle="setIsChangingAll(!selected)"
          />
          {{ $t('applyToAll') }}
        </template>
      </Customization>
    </template>
    <ImageC
      v-for="image in category.values"
      :key="image.c_valore"
      :status="image.c_valore === category.selected ? 'selected' : 'active'"
      :color="`rgb(${image.n_R}, ${image.n_G}, ${image.n_B})`"
      @click.native="changeState(category.parentId || category.id, image.c_valore)"
    >
      <template
        v-if="image.imageUrl"
        v-slot:image
      >
        <img :src="image.imageUrl">
      </template>
      <template v-slot:label>
        {{ image.ds_valore }}
      </template>
    </ImageC>
  </ImagesCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import ImagesCard from 'ui/src/components/ImagesCard'
import ImageC from 'ui/src/components/ImagesCard/Image'
import Customization from 'ui/src/components/CustomizationsCard/Customization'
import SwitcherC from 'ui/src/components/CustomizationsCard/Types/Switcher'

import changeState from '../mixins/changeState'

export default {
  name: 'ImagesCardMenu',
  components: { Customization, ImagesCard, ImageC, SwitcherC },
  mixins: [changeState],
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({ modelId: 'modelId', selected: 'isChangingAll', editAll: 'editAll', isValidForMultiChange: 'isValidForMultiChange' }),
    isVisible () {
      if (this.isValidForMultiChange) {
        return true
      }
      if (this.category.parentId) {
        return (this.category.parentId.includes('FAS') || this.category.parentId.includes('FIA'))
      }
      return false
    }
  },
  methods: {
    ...mapMutations(['setIsChangingAll'])
  },
  i18n: {
    messages: {
      'it-IT': {
        applyToAll: 'APPLICA A TUTTI'
      },
      en: {
        applyToAll: 'APPLY TO ALL'
      },
      fr: {
        applyToAll: 'APPLIQUE A\' TOUS'
      },
      es: {
        applyToAll: 'APLICA A TODOS'
      }
    }
  }
}
</script>
