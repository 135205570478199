import mboxGeo from '@mapbox/mapbox-sdk/services/geocoding'

export default {
  data: () => ({
    cities: [],
    forceCityClose: true,
    savedCity: undefined,
    mapBoxGeo: undefined
  }),
  computed: {
    country: {
      get () {
        if (!this.savedCity || !this.savedCity.context) return null
        const countryContext = this.savedCity.context.find(ctx => ctx.id.includes('country'))
        return countryContext.short_code.toUpperCase()
      }
    },
    region: {
      get () {
        if (!this.savedCity || !this.savedCity.context) return null
        const regionContext = this.savedCity.context.find(ctx => ctx.id.includes('region'))
        return (regionContext.short_code ? regionContext.short_code.split('-')[1] : regionContext.text).toUpperCase()
      }
    }
  },
  watch: {
    'form.city': {
      handler (v) {
        if (!this.form) return
        if (this.savedCity?.text === v) return
        this.checkGeo()
      }
    }
  },
  mounted () {
    this.mapBoxGeo = mboxGeo({ accessToken: 'pk.eyJ1IjoibGFnb2Rlc2lnbiIsImEiOiJjanIxc3hpaDcwcmVtM3hydTZveTZrcTBiIn0.VnmaRvgxt_R5tHG-n8ObVg' })
  },
  methods: {
    checkOrClear () {
      if (!this.form) return
      window.setTimeout(() => {
        this.forceCityClose = true
        if (this.savedCity && this.form.city === this.savedCity.text) return
        this.form.city = ''
      }, 250)
    },
    saveCity (city) {
      if (!this.form) return
      this.forceCityClose = true
      this.form.city = city.text
      this.savedCity = city
    },
    async checkGeo () {
      if (!this.form) return
      if (this.form.city.length < 2) return
      this.forceCityClose = false

      const res = await this.mapBoxGeo.forwardGeocode({
        query: this.form.city,
        language: [this.$i18n.locale.split('-')[0]],
        // ...(this.$i18n.locale.split('-')[1] ? { countries: [this.$i18n.locale.split('-')[1]] } : {}),
        types: ['place']
      })
        .send()

      this.cities = res.body.features
    }
  }
}
