import * as contentful from 'contentful'

// const client = contentful.createClient({
//   space: '4zg16q5vss6b',
//   accessToken: 'BD6CeUN9DHwTLI2YhQPrWbpEdoSOw5UCHEofyiiY6B4'
// })
const client = contentful.createClient({
  // space: 'h3y96kc2zt6a',
  // accessToken: 'F68eDpA5Go4jlQQfLpDRZfgNc9KR7gq8SQiiRra0Ouc'
  space: '4zg16q5vss6b',
  accessToken: 'BD6CeUN9DHwTLI2YhQPrWbpEdoSOw5UCHEofyiiY6B4',
  environment: 'master'
  // accessToken: '-lcTAAluM-IMQOoqIOdy2npWoFi9D-6HGHl3lFx97fk',
})

export const contentfulPlugin = {
  install (Vue) {
    Vue.contentful = client
    Vue.prototype.$contentful = client
  }
}
