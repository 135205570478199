import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { gsap } from 'gsap'
import menu from './menu'
import axios from 'axios'
// import Cookies from 'js-cookie'

Vue.use(Vuex)

let animateCamera
const camera = { zoom: 0 }

const setAnimateCamera = (camera3d) => {
  animateCamera = gsap.timeline({
    repeat: -1,
    yoyo: true,
    paused: true,
    onUpdate: () => {
      camera3d.zoomCamera(camera.zoom)
    }
  })
    .fromTo(camera,
      {
        zoom: -0.0325
      },
      {
        zoom: 0.0325,
        ease: 'expo.inOut',
        duration: 1.4
      })
}

export default new Vuex.Store({
  state: {
    modelId: undefined,
    selectedProduct: undefined,
    currentEntryId: undefined,
    _modelOptions: {},
    appViewerGetter: undefined,
    screenshot: undefined,
    appViewerReady: false,
    articleId: undefined,
    availableConfigurations: [],
    loader: true,
    siblings: undefined,
    dressCount: 0,
    dataSent: 0,
    url: undefined,
    group: undefined,
    isChangingGroup: false,
    lastChange: undefined,
    changingAll: false,
    editAll: false,
    isValidForMultiChange: false,
    isMultiLevel: false,
    children: [],
    editSingle: false,
    pro: false,
    userDetails: null,
    showCheckoutForm: false
  },
  getters: {
    modelId: state => state.modelId,
    token: state => state.token,
    selectedProduct: state => state.selectedProduct,
    modelOptions: state => state._modelOptions,
    appViewerGetter: state => state.appViewerGetter,
    screenshot: state => state.screenshot,
    appViewerReady: state => state.appViewerReady,
    availableConfigurations: state => state.availableConfigurations,
    loader: state => state.loader,
    articleId: state => state.articleId,
    siblings: state => state.siblings,
    dressCount: state => state.dressCount,
    lastChange: state => state.lastChange,
    dataSent: state => {
      if (typeof state.dataSent === 'boolean') return false
      return Math.floor(Date.now() / 1000) - state.dataSent < (1000 * 60 * 60 * 8)
    },
    url: state => state.url,
    productGroup: state => state.group,
    changingGroup: state => state.isChangingGroup,
    isChangingAll: state => state.changingAll,
    isValidForMultiChange: state => state.isValidForMultiChange,
    editAll: state => state.editAll,
    isMultiLevel: state => state.isMultiLevel,
    children: state => state.children,
    editSingle: state => state.editSingle,
    pro: state => state.pro,
    userDetails: state => state.userDetails,
    showCheckoutForm: state => state.showCheckoutForm
  },
  mutations: {
    setShowCheckoutForm (state, value) {
      state.showCheckoutForm = value
    },
    setModelId (state, id) {
      if (typeof state.modelId !== 'undefined') state.modelOptions = {}
      state.modelId = id
      state.isValidForMultiChange = false
    },
    setToken (state, token) {
      state.token = token
    },
    setModelOptions (state, options) {
      state._modelOptions = options
    },
    setLastChange (state, value) {
      state.lastChange = value
    },
    setAppViewerGetter (state, appViewerGetter) {
      state.appViewerGetter = appViewerGetter
    },
    setAppViewerReady (state, appViewerReady) {
      state.appViewerReady = appViewerReady
    },
    setArticleId (state, id) {
      state.articleId = id
    },
    setAvailableConfigurations (state, configurations) {
      state.availableConfigurations = configurations
    },
    setLoader (state, loader) {
      state.loader = loader
      if (!state.appViewer) return
      if (!animateCamera) {
        setAnimateCamera(state.appViewer.engine3d)
      }
      if (!state.loader) {
        const latestZoom = camera.zoom
        animateCamera.pause()
        gsap.to(camera, { zoom: latestZoom * -1, ease: 'expo.out', duration: 0.4, onUpdate: () => { state.appViewer.engine3d.zoomCamera(camera.zoom) } })
      }
      if (state.loader) {
        animateCamera.play()
      }
    },
    dress (state) {
      state.dressCount = state.dressCount + 1
    },
    setScreenshot (state, screenshot) {
      state.screenshot = screenshot
    },
    setSelectedProduct (state, selectedProduct) {
      state.selectedProduct = selectedProduct
    },
    setLocale (state, locale) {
      state.appViewerGetter().api.setLanguage(locale)
    },
    setSiblings (state, siblings) {
      state.siblings = siblings
    },
    setDataSent (state) {
      state.dataSent = Math.floor(Date.now() / 1000)
    },
    setUrl (state, url) {
      state.url = url
    },
    setProductGroup (state, groupData) {
      if (groupData) {
        state.group = {
          floor: groupData.floor.fields ? { id: groupData.floor.fields.models[0].fields.slug, image: groupData.floor.fields.cover.fields.file.url } : null,
          legs: groupData.legs.fields ? { id: groupData.legs.fields.models[0].fields.slug, image: groupData.legs.fields.cover.fields.file.url } : null,
          wall: groupData.wall.fields ? { id: groupData.wall.fields.models[0].fields.slug, image: groupData.wall.fields.cover.fields.file.url } : null
        }
      } else {
        state.group = undefined
      }
    },
    setIsChangingGroup (state, val) {
      // Cookies.set('changing_group', 1)
      // Cookies.set('model_options', JSON.stringify({ ...state._modelOptions }))
      state.isChangingGroup = val
    },
    setIsChangingAll (state, val) {
      state.changingAll = val
    },
    setEditAll (state, val) {
      state.editAll = val
    },
    setValidForMultiChange (state, val) {
      state.isValidForMultiChange = val
    },
    setIsMultiLevel (state, val) {
      state.isMultiLevel = val
    },
    setChildren (state, val) {
      state.children = val
    },
    setEditSingle (state, val) {
      state.editSingle = val
    },
    setPro (state, val) {
      state.pro = val
    },
    setUserDetails (state, value) {
      state.userDetails = value
    },
    logOut (state) {
      state.userDetails = null
    }
  },
  actions: {
    async changeModelState (context, newState) {
      context.commit('setLoader', true)
      if (context.getters.isChangingAll) {
        // const changes = []
        // for (const pair of newState) {
        //   changes.push({
        //     c_categoria: pair.category,
        //     c_valore: pair.value
        //   })
        // }
        // context.state.appViewerGetter().getElementSelected().articoloGemo.setVariantiScelte(changes)
        // await context.state.appViewerGetter().getElementSelected().articoloGemo.vesti()
        // await context.state.appViewerGetter().configureElementVariant(context.state.appViewerGetter().getElementSelected(), changes[0].c_categoria, [changes[0].c_valore])
        let options = Object.assign({}, context.getters.modelOptions)
        for (const pair of newState) {
          options = Object.assign(options, { [pair.category]: pair.value })
        }

        context.commit('setModelOptions', options)
        context.commit('setIsChangingAll', false)
      } else {
        context.commit(
          'setModelOptions',
          Object.assign({}, context.getters.modelOptions, { [newState.category]: newState.value })
        )
      }
      context.commit('setLoader', false)
    },
    async getScreenshot (context) {
      if (!context.getters.appViewerGetter) return
      const screenshot = await context.getters.appViewerGetter().takeDefaultScreenShot({
        opentab: false
      })
      // const screenshot = await context.getters.appViewerGetter().takeScreenShot()
      context.commit('setScreenshot', screenshot.b64Data)
    },
    async sendForm ({ commit }, formObj) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'JWT fefege...'
      }
      const infos = await axios.post('https://europe-west1-lago-master.cloudfunctions.net/leads-fakeEcommerce',
        formObj,
        { headers: headers })
      return infos
    }
  },
  modules: {
    menu
  },
  plugins: [createPersistedState({
    paths: ['dataSent']
  })]
})
