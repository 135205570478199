<template>
  <CustomizationsCard
    v-if="category"
    :overlap="true"
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ category.name }}
    </template>
    <Customization
      v-for="option of category.values"
      :key="`${option.parentId}_${option.id}`"
      @click.native="setTypeAndNavigate(option)"
    >
      <template v-slot:icon>
        <SelectC :status="option.values.some((o) => o.c_valore === option.selected) ? 'selected' : 'active'" />
      </template>
      <template v-slot:label>
        {{ option.name }}
      </template>
    </Customization>
  </CustomizationsCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CustomizationsCard from 'ui/src/components/CustomizationsCard'
import Customization from 'ui/src/components/CustomizationsCard/Customization'
import SelectC from 'ui/src/components/CustomizationsCard/Types/Select'

import changeState from '../mixins/changeState'

export default {
  components: {
    Customization,
    CustomizationsCard,
    SelectC
  },
  mixins: [changeState],
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  i18n: {
    messages: {
      'it-IT': {
        toolbar: {
          inspirations: {
            main: 'Trova ispirazione'
          },
          customizations: {
            main: 'Personalizzazioni',
            sizes: 'Dimensioni'
          }
        }
      },
      en: {
        toolbar: {
          inspirations: {
            main: 'I need inspiration'
          },
          customizations: {
            main: 'Customisation options',
            sizes: 'Size'
          }
        }
      },
      fr: {
        toolbar: {
          inspirations: {
            main: 'Trouver l’inspiration'
          },
          customizations: {
            main: 'Personnalisations',
            sizes: 'Dimensions'
          }
        }
      },
      es: {
        toolbar: {
          inspirations: {
            main: 'Encuentra inspiración'
          },
          customizations: {
            main: 'Personalizaciones',
            sizes: 'Dimensiones'
          }
        }
      }
    }
  },
  data: () => ({
    presets: undefined
  }),
  computed: {
    ...mapGetters({ availableConfigurations: 'availableConfigurations', modelId: 'modelId', modelOptions: 'modelOptions' })
  },
  watch: {
    modelId: {
      async handler () {
        if (!this.modelId) return
        const presets = await this.$contentful.getEntries({
          content_type: 'preset',
          include: 10,
          locale: this.$i18n.locale
        })
        this.presets = presets.items.filter((preset) => preset.fields.model.fields.tesyId === this.modelId)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['setValidForMultiChange']),
    setTypeAndNavigate (option) {
      const isValidForMultiChange = (option.parentId.includes('DSED') || option.parentId.includes('TSCH') || option.parentId.includes('TCUS'))
      this.setValidForMultiChange(isValidForMultiChange)
      this.$emit('open', `${option.parentId}_${option.id}`)
    },
    getSelectedColor (option) {
      const selected = option.values
        .find((val) => val.c_valore === this.modelOptions[option.id])
      if (!selected) return 'rgb(0, 0, 0)'
      return `rgb(${selected.n_R}, ${selected.n_G}, ${selected.n_B})`
    },
    getSelectedImage (option) {
      const selected = option.values
        .find((val) => val.c_valore === this.modelOptions[option.id])
      if (!selected) return ''
      return selected.imageUrl
    },
    getSelectedSwitch (option) {
      const selected = option.values
        .find((val) => val.c_valore === this.modelOptions[option.id])
      if (!selected) return false
      return selected.c_valore === 'SI'
    }
  }
}
</script>
