<template>
  <div style="position: relative">
    <component :is="transitionType">
      <CustomizationsCard
        v-if="isOpen('customize')"
        :has-siblings="siblings && siblings.length > 1"
        :has-variants="variants && variants.length > 1"
        :has-levels="dimLevels && dimLevels.length > 1"
        @open="(card) => handleOpen(card)"
        @close="isOpen('inspirations') ? closeMenuCard(['customize', 'inspirations']) : closeMenuCard('customize')"
      />
    </component>

    <component
      :is="transitionType"
      v-for="(optionCategory, i) of fullAvailableConfigurations.filter((c) => c.type !== 'switcher')"
      :key="`optioncategory_i${i}`"
    >
      <component
        :is="`${optionCategory.type}Card`"
        v-if="isOpen(optionCategory.id)"
        :category="optionCategory"
        :style="optionCategory.type === 'list' ? 'width: 100%;' : 'width: auto;'"
        @close="closeMenuCard(optionCategory.id)"
        @open="(card) => openMenuCard(card)"
      />
    </component>

    <component
      :is="transitionType"
      v-for="(optionCategory, j) of fullAvailableConfigurations"
      :key="`optioncategory_j${j}`"
    >
      <template v-for="(optionValue, i) of optionCategory.values">
        <component
          :is="`${optionValue.type}Card`"
          v-if="isOpen(`${optionValue.parentId}_${optionValue.id}`)"
          :key="`optionValue_i${i}${optionValue.parentId}_${optionValue.id}`"
          :category="optionValue"
          :style="optionValue.type === 'list' ? 'width: 100%;' : 'width: auto;'"
          @close="closeMenuCard(`${optionValue.parentId}_${optionValue.id}`)"
          @open="(card) => openMenuCard(card)"
        />
      </template>
    </component>

    <component :is="transitionType">
      <SiblingsCard
        v-if="isOpen('siblings')"
        :siblings="siblings"
        @close="closeMenuCard('siblings')"
      />
    </component>
    <component :is="transitionType">
      <DimLevelsCard
        v-if="isOpen('dimLevels')"
        :dimensions="dimLevels"
        @close="closeMenuCard('dimLevels')"
      />
    </component>
    <component :is="transitionType">
      <VariantsCard
        v-if="isOpen('variants')"
        :variants="variants"
        @close="closeMenuCard('variants')"
      />
    </component>
    <component :is="transitionType">
      <SupportCard
        v-if="isOpen('support')"
        @close="closeMenuCard('support')"
      />
    </component>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import CustomizationsCard from './CustomizationCard'

import selectCard from './SelectCard'
import imageCard from './ImagesCard'
import listCard from './ListCard'
import SiblingsCard from './SiblingsCard'
import SupportCard from './SupportCard'
import VariantsCard from './VariantsCard'
import DimLevelsCard from './DimLevelsCard'

import SlideRight from 'ui/src/components/Transitions/SlideRight'
import SlideUp from 'ui/src/components/Transitions/SlideUp'

const extractDimensions = (group) => {
  const { fields } = group
  // const name = fields.name
  const heights = fields.dimensions.map(dim => dim.fields)
  const rows = heights.map(he => ({
    h: he.h,
    widths: he.widths.map(wi => {
      return { w: wi.fields.w, model: wi.fields.model.fields.tesyId, id: wi.fields.model.sys.id }
    })
  }))
  return rows
}

export default {
  components: {
    CustomizationsCard,
    selectCard,
    imageCard,
    SiblingsCard,
    SlideRight,
    listCard,
    SlideUp,
    SupportCard,
    VariantsCard,
    DimLevelsCard
  },
  props: {
    transitionType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      siblings: false,
      variants: false,
      dimLevels: false
    }
  },
  computed: {
    ...mapGetters({ availableConfigurations: 'availableConfigurations', selectedProduct: 'selectedProduct', appViewerGetter: 'appViewerGetter' }),
    ...mapGetters('menu', { isOpen: 'isMenuCardOpen' }),
    fullAvailableConfigurations: {
      get () {
        if (!this.availableConfigurations.some((option) => option.type === 'list')) return this.availableConfigurations
        const lists = this.availableConfigurations.find((option) => option.type === 'list').values
        return this.availableConfigurations.concat(lists)
      }
    }
  },
  watch: {
    'selectedProduct.model': {
      async handler () {
        if (!this.selectedProduct) return

        const mads = await this.$contentful.getEntries({
          content_type: 'madieWidths',
          links_to_entry: this.selectedProduct.model,
          limit: 1,
          locale: this.$i18n.locale
        })

        if (mads.items[0]) {
          const heights = await this.$contentful.getEntries({
            content_type: 'madieDimensions',
            links_to_entry: mads.items[0].sys.id,
            limit: 1,
            locale: this.$i18n.locale
          })

          if (heights.items[0]) {
            const madieGroup = await this.$contentful.getEntries({
              content_type: 'groupMadie',
              links_to_entry: heights.items[0].sys.id,
              limit: 1,
              include: 4,
              locale: this.$i18n.locale
            })

            if (madieGroup.items[0]) {
              const rows = extractDimensions(madieGroup.items[0])
              const __sibs = rows.map((row) => ({
                tesyId: row.model,
                id: row.id,
                h: row.h,
                widths: row.widths
              }))
              this.dimLevels = __sibs
            }
          }
        }

        let dimensions = []
        const dim = await this.$contentful.getEntries({
          content_type: 'dimension',
          links_to_entry: this.selectedProduct.model,
          limit: 1,
          locale: this.$i18n.locale
        })

        if (dim.items[0]) {
          const groupDim = await this.$contentful.getEntries({
            content_type: 'groupDimensions',
            links_to_entry: dim.items[0].sys.id,
            locale: this.$i18n.locale,
            include: 2
          })

          if (groupDim.items[0]) {
            dimensions = groupDim.items[0].fields.dimensions.map(d => ({
              label: {
                L: d.fields.l ? d.fields.l + ' cm' : '',
                P: d.fields.p ? d.fields.p + ' cm' : ''
              },
              tesyId: d.fields.model ? d.fields.model.fields.tesyId : '',
              id: d.fields.model ? d.fields.model.sys.id : ''
            }
            ))
          }
        }

        if (dimensions.length > 0) {
          this.siblings = dimensions
        } else {
          if (!this.dimLevels) {
            const siblings = await this.$contentful.getEntries({
              links_to_entry: this.selectedProduct.model,
              content_type: 'product',
              locale: this.$i18n.locale
            }).then(products => products.items[0].fields.models)
            this.siblings = siblings
              .map((model) => ({
                tesyId: model.fields.tesyId,
                id: model.sys.id,
                label: {
                  [model.fields.raw?.Elemento?.DatiArticolo?.c_sigla_dim_1?.charAt(0) || 'L']: (model.fields.raw?.Elemento?.DatiArticolo?.n_dim_1 || '') + ' ' + model.fields.raw?.Elemento?.DatiArticolo?.c_um_dimensioni.toLowerCase(),
                  [model.fields.raw?.Elemento?.DatiArticolo?.c_sigla_dim_2?.charAt(0) || 'P']: (model.fields.raw?.Elemento?.DatiArticolo?.n_dim_2 || '') + ' ' + model.fields.raw?.Elemento?.DatiArticolo?.c_um_dimensioni.toLowerCase()
                }
              }))
          }
        }

        this.setSiblings(this.siblings)
        let variants = []
        const variant = await this.$contentful.getEntries({
          content_type: 'variants',
          links_to_entry: this.selectedProduct.model,
          limit: 1,
          locale: this.$i18n.locale
        })
        if (variant.items[0]) {
          const groupVars = await this.$contentful.getEntries({
            content_type: 'groupVariants',
            links_to_entry: variant.items[0].sys.id,
            locale: this.$i18n.locale,
            include: 2
          })

          if (groupVars.items[0]) {
            variants = groupVars.items[0].fields.variants.map(d => ({
              name: d.fields.design,
              tesyId: d.fields.model ? d.fields.model.fields.tesyId : '',
              id: d.fields.model ? d.fields.model.sys.id : ''
            }
            ))
          }
        }

        if (variants.length > 0) {
          this.variants = variants
          this.siblings = false
        } else {
          this.variants = false
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('menu', { openMenuCard: 'openMenuCard', closeMenuCard: 'closeMenuCard' }),
    ...mapMutations({ setSiblings: 'setSiblings' }),
    async handleOpen (card) {
      const configurationToOpen = this.availableConfigurations.find((c) => c.id === card)
      const base = this.appViewerGetter().getElementSelected().articoloGemo.articoliGemoChildMap[0]
      await this.appViewerGetter().getElementSelected().articoloGemo.setArticoloGemoSelected(base)

      if (!configurationToOpen || configurationToOpen.type !== 'list' || configurationToOpen.values.length > 1) {
        this.openMenuCard(card)
        return
      }
      this.openMenuCard(`${card}_${configurationToOpen.values[0].id}`)
    }
  }
}
</script>
