<template>
  <TopBar
    :root-url="rootUrl"
    @logo-click="openExitModal"
  >
    <template v-slot:exitModal>
      <Modal
        ref="modal"
        class="exitModal"
        @submit="sendExitMessage"
      >
        <template v-slot:title>
          <div class="title">
            {{ $t('topbar.exitModal.title') }}
          </div>
        </template>
        <template v-slot:description>
          <div class="desc">
            {{ $t('topbar.exitModal.description') }}
          </div>
        </template>
        <template v-slot:button>
          {{ $t('topbar.exitModal.cta') }}
        </template>
      </Modal>
    </template>
  </TopBar>
</template>

<script>
import TopBar from 'ui/src/components/TopBar'
import Modal from 'ui/src/components/Modal'

export default {
  components: {
    TopBar,
    Modal
  },
  i18n: {
    messages: {
      'it-IT': {
        topbar: {
          exitModal: {
            title: 'Vuoi tornare al sito Lago.it?',
            description: 'Confermando, tutte le modifiche apportate nel configuratore andranno perse',
            cta: 'TORNA AL SITO'
          }
        }
      },
      en: {
        topbar: {
          exitModal: {
            title: 'Go back to lago.it?',
            description: 'By confirming, all changes made in the configurator will be lost.',
            cta: 'RETURN TO THE WEBSITE'
          }
        }
      },
      fr: {
        topbar: {
          exitModal: {
            title: 'Souhaitez-vous revenir au site Lago.it ?',
            description: 'Si vous confirmez, toutes les modifications apportées dans le configurateur seront perdues.',
            cta: 'REVENIR AU SITE'
          }
        }
      },
      es: {
        topbar: {
          exitModal: {
            title: '¿Quieres volver al sitio web Lago.it?',
            description: 'Confirmando, todas las modificaciones aportadas en el configurador se perderán',
            cta: 'VOLVER AL SITIO WEB'
          }
        }
      }
    }
  },
  computed: {
    rootUrl: {
      get () {
        return process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://lago.netlify.app'
      }
    }
  },
  methods: {
    sendExitMessage () {
      parent.postMessage({ type: 'click', el: 'logo' }, '*')
    },
    openExitModal () {
      console.log('TEST c')

      this.$refs.modal.toggle()
    }
  }
}
</script>

<style lang="scss" scoped>
.exitModal {
  .title {
    margin-bottom: 20px;
  }

  .desc {
    margin-bottom: 20px;
  }
}
</style>
