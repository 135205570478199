<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ modelOptions: 'modelOptions', modelId: 'modelId', appViewerGetter: 'appViewerGetter', changingGroup: 'changingGroup' }),
    query: {
      get () {
        return Object.assign({}, this.$route.query, {
          model: this.modelId,
          options: this.modelOptions
        })
      }
    }
  },
  watch: {
    query: {
      handler (value, oldValue) {
        // first load
        if (typeof value.model === 'undefined') return
        // no changes
        if (JSON.stringify(value.options) === JSON.stringify(oldValue.options) && value.model === oldValue.model) return
        if (JSON.stringify(this.$route.query) === JSON.stringify(this.query)) return

        // new model, reset options
        if (oldValue.model && value.model !== oldValue.model) {
          this.$router.replace({ query: { model: value.model, options: this.modelOptions } })
          return
          // if (this.changingGroup) {
          // } else {
          //   this.$router.replace({ query: { model: value.model, options: this.modelOptions } })
          // }
        }
        // new options, change query
        this.$router.replace({ query: this.query })
      },
      deep: true
    }
  },
  render: () => ''
}
</script>
